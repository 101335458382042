import { useState } from "react"
import { setFilter, clearFilter } from "../features/PetitionFilterSlice"
import { useSelector, useDispatch } from "react-redux"
import { FaArrowLeft, FaArrowRight, FaCalendar } from "react-icons/fa"

const currentYear = 2023
const leastYear = 1999
const maxYear = currentYear + 4

const Calendar = (props)=>{

    const {collapsed = true} = props
    const [startYear, setStartYear] = useState(currentYear - 4)
    const [selectedYear, setSelectedYear] = useState(currentYear)

    const dispatch = useDispatch()

    /**
     * Data is always filtered by year, this filter cannnot be cleared
     * @param {*} year 
     */
    const setYearFilter = (year)=>{
        dispatch(setFilter({
            key: props.fid,
            filter: "year",
            value: year
        }))
    }

    const yearLinks = [...(Array(5).keys())].map((value)=>{
        const year = value * 1 + startYear
        return <a key={year} onClick={()=>{setYearFilter(year); setSelectedYear(year)}} className={["cursor-pointer", "filter-item", (year == selectedYear)? "item-active" : ""].join(" ")}>{year}</a>
    })

    const calendar = (
        <div onClick={()=>{props.onCalendarClick()}} className="cursor-pointer overflow-hidden transition-all transition-1000 h-full w-full flex justify-center bg-white items-center shadow-[0px_0px_15px_#c6E4FF7F] border-[#2D9BFF40] border-[1px] rounded-[25px]">
            <FaCalendar className="fa-solid fa-calendar text-[18pt] text-primary"/>
        </div>
    )

    return (
        (collapsed)? calendar :
        <div className="overflow-hidden transition-all transition-1000 h-full flex justify-between px-searchbar_padding bg-[#F0F8FF] items-center shadow-[0px_0px_15px_#c6E4FF7F] border-[#2D9BFF40] border-[1px] rounded-full">
            <a onClick={()=>{if(startYear > leastYear){setStartYear(startYear - 1)}}} className="cursor-pointer text-[15pt] bg-primary text-white rounded-full p-[10px_12px]"><FaArrowLeft /></a>

            {yearLinks}

            <a onClick={()=>{if(startYear < maxYear - 4){setStartYear(startYear + 1)}}} className="cursor-pointer text-[15pt] bg-primary text-white rounded-full p-[10px_12px]"><FaArrowRight/></a>
        </div>
    );
}

export default Calendar;