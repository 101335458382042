import React, { Component } from "react"
import ApexChart from "react-apexcharts"

class BarChart extends Component {
    constructor(props) {
      super(props);

      const { legend = false, horizontal = false } = props

      this.state = {
      
        series: [{
          name: 'Petitions',
          data: props.data
        }],
        options: {
          chart: {
            height: 350,
            type: 'bar',
          },
          plotOptions: {
            bar: {
              distributed: true,
              horizontal: horizontal,
              borderRadius: 5,
              borderRadiusApplication: 'end',
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            },            
          },
          colors: props.colors,
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: (!horizontal)? -20 : 0,
            offsetX: (horizontal)? 20 : 0,
            style: {
              fontSize: '8pt',
              colors: ["#034179"],
              fontFamily: 'Montserrat'
            }
          },
          
          xaxis: {
            categories: props.labels,
            position: 'bottom',
            axisBorder: {
              show: true
            },
            axisTicks: {
              show: true
            },
            crosshairs: {
              fill: {
                colors: ['#034179']
              }
            },
            tooltip: {
              enabled: true,
            }
          },
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: true,
            },
            labels: {
              show: true,
              formatter: function (val) {
                return val;
              }
            }
          },
          legend: {
            show: legend
          },
          title: {
            text: props.title,
            floating: true,
            offsetY: 0,
            align: 'center',
            style: {
              color: '#034179',
              fontSize: '9pt',
              fontWeight: '700',
              fontFamily: 'Montserrat',
            }
          }
        },
      
      
      };
    }

    render() {
      return (
        <div>
          <div id="chart">
            <ApexChart options={this.state.options} series={this.state.series} type="bar" />
          </div>
          <div id="html-dist"></div>
        </div>
      );
    }
  }

  export default BarChart;