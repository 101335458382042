import { useState, useRef } from "react"
import { FaSearch, FaAngleLeft, FaAngleRight } from "react-icons/fa"
import LinearProgress from '@mui/material/LinearProgress'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import axios from "axios"
import toast from "react-hot-toast"

const HOST = process.env.REACT_APP_API_HOST

export default (props) => {

    const onCloseOverlay = props.onCloseOverlay
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)

    const searchText = useRef()

    const onSearch = ()=>{
        const query = searchText.current.value
        performSearch(query)
    }

    const pageChangeHandler = (newPage)=>{
        setCurrentPage(newPage)
    }

    const performSearch = (query) => {
        const url = HOST + "/api/search?q=" + query
        setIsLoading(true)

        axios.get(url).then((res)=>{
            if(res.status == 200){
                if(res.data.status){
                    setData(res.data.data)
                }
            }else{
                toast.error("Something went wrong, please try again")
            }
        }).catch((error)=>{
            console.error(error.toString())
        }).finally(()=>{
            setIsLoading(false)
        })
    }

    return (
        <>
            <div className="Search_body absolute w-full bg-blue z-[3000] h-[100vh] " style={{ display: "absolute", "overflow-y": "auto" }}>
                {/* Loading bar */}
                {
                    (isLoading)? 
                    <Box sx={{ width: "100%" }}>
                        <LinearProgress />
                    </Box> : ""
                }
                <div id="seach_page" name="search_section" className="h-full">
                    <div id="Search" className="h-full">
                        <div className="h-full grid" style={{"grid-template-rows": "100px 35px auto auto", "grid-template-columns": "100%"}}>
                            <div className="search_nav_gap flex justify-between gap-[100px] max-[500px]:gap-[20px] px-[5%]">
                                <div className="w-[300px] flex items-center">
                                    <img className="logo_width" src="/assets/img/KDI_White_logo.png" alt="KDI" />
                                </div>

                                {/* <div id="search_nav_menu" className="max-[500px]:gap-[15px] min-[700px]:w-full flex justify-center search_nav_menu">

                                </div> */}
                                <div className="w-[100px] flex items-center justify-end">
                                    <ion-icon onClick={onCloseOverlay} id="searchtoggle" class="text-white text-[20pt] hover:text-high_ctrst hover:cursor-pointer" name="close-circle-outline"></ion-icon>
                                </div>
                            </div>

                            <div id="search_nav_menu" className="max-[500px]:gap-[15px] gap-[50px] flex w-full justify-center search_nav_menu">
                                <a className="hover:text-high_ctrst" href="/">Home</a>
                                <a className="hover:text-high_ctrst" href="/about">About</a>
                                <a className="hover:text-high_ctrst" href="/blog">Blog</a>
                                <a className="hover:text-high_ctrst" href="/resources">Resources</a>
                            </div>
                            
                            <div className="px-[10%] max-[500px]:px-[5%]">
                                <div className="flex flex-col items-center lg:flex lg:flex-row-reverse md:align-middle lg:justify-around w-full">
                                    <div className="py-[25px] px-[15px] search_input_bar flex items-center content-center w-full my-[50px]">
                                        <FaSearch className="text-[16pt] text-[#505050]" />
                                        <input ref={searchText} onKeyDown={(e)=>{if(e.key == "Enter"){onSearch()}}} id="email_input" aria-label="emailhelpertext" className="w-full bg-transparent rounded-[10px] bg-primary/5 p-[10px] text-2xs font-Montserrat font-semi-bold text-[#505050]" type="text" placeholder="Type something here" />
                                    </div>
                                </div>

                                <SearchResult onPageChange={pageChangeHandler} data={data} page={currentPage}/>

                            </div>

                            <div className="logo_image_container bg-white h-[10vh] p-[10px]" style={{"align-self": "end"}}>
                                <img className="w-[auto] h-full partner_logos" src="/assets/img/Partner logos.png" alt="USAID" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const SearchResult = (props) => {
    const data = props.data
    const page = props.page
    const onPageChange = props.onPageChange
    if(data == null){
        return null
    }

    return <div>
                <p className="font-Montserrat text-white font-semibold text-xl pl-[20px]">Search Result</p>
                
                <div className="flex items-center text-white mb-[20px]">
                    <p className="font-Montserrat w-full text-white text-[9pt] pl-[35px] mt-[5px]">{data.total_results} results found</p>
                    <div className="flex w-[200px] font-Montserrat items-center justify-end">
                        <Button sx={{color: "white", minWidth: "30px"}} onClick={()=>{if(page > 1){ onPageChange(page - 1) }}}>
                            <a className="cursor-pointer"><FaAngleLeft/></a>
                        </Button>
                        <p className="text-[9pt] px-[10px]">{page} of {data.pages}</p>
                        <Button sx={{color: "white", minWidth: "30px"}} onClick={()=>{if(page < data.pages){ onPageChange(page + 1) }}}>
                            <a className="cursor-pointer"><FaAngleRight/></a>
                        </Button>
                    </div>
                </div>                                   
                

                <div className="resultPane bg-white opacity-50 w-full rounded-[10px] p-[35px] mb-[50px]">
                    { (data.total_results > 0)?
                        data.search_results[page - 1].map((result)=>{
                            const link = `https://ept.kimpact.org.ng/blog/story/${result.id}`
                            return (
                                <div className="mb-[25px]">
                                    <a href={link} target="_blank" className="font-Montserrat">
                                        <p className="text-[13pt] font-semibold underline text-primary hover:text-secondary">{result.title}</p>
                                        <p className="text-[9pt] break-words">{link}</p>
                                        <p className="mt-[5px] text-[10pt]">
                                            { result.paragraphs.substring(0, 220) }...
                                        </p>
                                    </a>
                                </div>
                            )
                        }) : ""
                    }
                    { (data.total_results == 0)? <p className="font-Montserrat text-[10pt] text-center font-medium">Nothing to see here</p> : "" }
                </div>
            </div>
}