import { Outlet, Link, useLocation } from "react-router-dom"

import Header from "../Header"
import Footer from "../Footer"
import { Toaster } from "react-hot-toast"

const MasterLayout = ({children})=>{
    const location = useLocation()

    return (
        <>
            <Toaster 
                position="top-right" 
                reverseOrder={true}
                toastOptions={{
                    className: '',
                    style: {
                      fontSize: '10pt',
                      fontWeight: "500",
                      fontFamily: 'Montserrat'
                    },
                }}
            />
            <Header/>
            <Outlet/>
            {(location.pathname != "/")? <Footer/> : <></>}
        </>
    );
}

export default MasterLayout;