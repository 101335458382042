import { Helmet } from "react-helmet"
import Paginator from "../components/Paginator"
import { FaDownload } from "react-icons/fa"
import { useState } from "react"
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query"
import axios from "axios"
import ClipLoader from "react-spinners/ClipLoader";
import toast from "react-hot-toast";
import fileDownload from "js-file-download"
import mime from "mime"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Parser } from "html-to-react"
import "../styles/BlogStory.css"

const queryClient = new QueryClient()
const HOST = process.env.REACT_APP_API_HOST

export default (props)=>{
    return <QueryClientProvider client={queryClient}>
        <BlogStory />
    </QueryClientProvider>
}

const formatDate = (dateString)=>{
    return new Date(dateString).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric"
    })
}

const BlogStory = (props)=>{
    const token = useSelector((state)=>state.authToken.token)
    const { storyId } = useParams()

    const url = HOST + `/api/blog/story/${storyId}`
    const { isLoading, error, data} = useQuery({
        queryKey: [url],
        queryFn: ()=>axios.get(url).then((res)=>{ return res.data }),
        retry: 3
    })

    const showContent = ()=>{
        if(isLoading){
            return <div style={{height: "30vh"}} className="flex justify-center items-center"><ClipLoader color="#034179" /></div>
        }else if(error || !data.status){
            return <p class="text-center mt-[20px] font-Montserrat text-primary">Unable to retrieve data, try again in a moment</p>
        }

        return (
            <div className="flex gap-[5%] max-[850px]:flex-col mt-[80px]">
                <div className="w-[70%] max-[850px]:w-full">
                    <h3 className="text-primary max-sm:text-[13pt] text-[18pt] font-medium cursor-pointer hover:text-[#139DB8] leading-[40px] max-sm:leading-[30px]">
                        <a>{data.data.story.title}</a>
                    </h3>
                    <p className="text-secondary font-Montserrat text-[10pt] font-semibold my-[30px]">
                        { formatDate(data.data.story.created_at) }
                    </p>

                    <img className="w-full" src={`https://eptdashboard.kimpact.org.ng/storage/${data.data.story.image}`} />

                    <div className="mt-[50px]">
                        <div className="blog-content text-primary">
                            { Parser().parse(data.data.story.body) }
                        </div>
                    </div>
                </div>

                <div className="w-[30%] max-[850px]:w-full">
                    <h3 className="text-primary text-[14pt] font-semibold mb-[20px]">Recent Stories</h3>

                    <div className="max-[850px]:grid grid-rows-2 grid-cols-2 gap-[20px] max-[500px]:block">
                        { data.data.recent_stories.map((story)=><SidePaneItem id={story.id} title={story.title} postDate={story.created_at}/>) }
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="BlogStory">
            <Helmet>
                <title>
                    { (isLoading)? "Blog Story" : data.data.story.title }
                </title>
            </Helmet>
                <div class="flex flex-col items-center justify-center px-[8%] min-[850px]:py-[50px]">
                    
                    { showContent() }

                </div>
        </div>
    )
}

const SidePaneItem = (props)=>{
    return (
        <div className="mb-[15px] bg-[#f3f3f3] p-[15px]">
            <a href={`/blog/story/${props.id}`} className="max-sm:text-[10pt] cursor-pointer hover:underline text-[#139DB8]">{props.title}</a>
            <p className="text-[9pt] font-Montserrat text-primary mt-[5px] font-medium">{formatDate(props.postDate)}</p>
        </div>
    )
}