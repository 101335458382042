import { useState, useEffect } from "react" 

const TableChartToggle = (props) => {
    const [selectedIndex, setSelectedIndex] = useState((props.isTable)? 0 : 1)

    const onToggle = props.onToggle
    const { option_a = "Table", option_b = "Chart"} = props

    // useEffect(()=>{
    // }, [selectedIndex])

    return (
        <>
            <div className="TableChartToggle w-[250px] h-full flex items-center justify-center shadow-[0px_0px_3px_#c6E4FF] border-[#2D9BFF40] border-[1px] rounded-full">
                <a className={["toggle-item", "cursor-pointer", "flex", "w-full", "h-full", "gap-[10px]", "justify-center", "items-center", (selectedIndex == 0)? "active" : ""].join(' ')}
                    onClick={
                        ()=>{
                            setSelectedIndex(0)
                            onToggle(true)
                        }}>
                    <svg className="fill-white" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path className="" d="M12.5 17.5H7.50002V8.33333H12.5V17.5ZM14.1667 17.5V8.33333H18.3334V16.6667C18.3334 16.8877 18.2456 17.0996 18.0893 17.2559C17.933 17.4122 17.721 17.5 17.5 17.5H14.1667ZM5.83335 17.5H2.50002C2.27901 17.5 2.06704 17.4122 1.91076 17.2559C1.75448 17.0996 1.66669 16.8877 1.66669 16.6667V8.33333H5.83335V17.5ZM18.3334 6.66667H1.66669V3.33333C1.66669 3.11232 1.75448 2.90036 1.91076 2.74408C2.06704 2.5878 2.27901 2.5 2.50002 2.5H17.5C17.721 2.5 17.933 2.5878 18.0893 2.74408C18.2456 2.90036 18.3334 3.11232 18.3334 3.33333V6.66667Z" fill="#034179" />
                    </svg>

                    <p className="font-[montserrat] filter-item text-primary">{option_a}</p>
                </a>

                <a className={["toggle-item", "cursor-pointer", "flex", "w-full", "h-full", "gap-[10px]", "justify-center", "items-center", (selectedIndex == 1)? "active" : ""].join(' ')}
                    onClick={
                        ()=>{
                            setSelectedIndex(1)
                            onToggle(false)
                        }}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="" fill-rule="evenodd" clip-rule="evenodd" d="M11.6667 17.0833V3.54163C11.6667 2.93496 11.665 2.54163 11.6267 2.25329C11.59 1.98246 11.5309 1.89746 11.4834 1.84996C11.4359 1.80246 11.3509 1.74329 11.08 1.70663C10.7909 1.66829 10.3984 1.66663 9.79169 1.66663C9.18502 1.66663 8.79169 1.66829 8.50335 1.70663C8.23252 1.74329 8.14752 1.80246 8.10002 1.84996C8.05252 1.89746 7.99335 1.98246 7.95669 2.25329C7.91835 2.54246 7.91669 2.93496 7.91669 3.54163V17.0833H11.6667Z" fill="#C6E4FF" />
                        <path opacity="0.7" d="M6.66669 7.29163C6.66669 7.12587 6.60084 6.96689 6.48363 6.84968C6.36642 6.73247 6.20745 6.66663 6.04169 6.66663H3.54169C3.37593 6.66663 3.21696 6.73247 3.09975 6.84968C2.98253 6.96689 2.91669 7.12587 2.91669 7.29163V17.0833H6.66669V7.29163ZM16.6667 11.4583C16.6667 11.2925 16.6008 11.1336 16.4836 11.0164C16.3664 10.8991 16.2074 10.8333 16.0417 10.8333H13.5417C13.3759 10.8333 13.217 10.8991 13.0997 11.0164C12.9825 11.1336 12.9167 11.2925 12.9167 11.4583V17.0833H16.6667V11.4583Z" fill="#C6E4FF" />
                        <path opacity="0.5" d="M1.45831 17.0834C1.29255 17.0834 1.13358 17.1492 1.01637 17.2664C0.899161 17.3836 0.833313 17.5426 0.833313 17.7084C0.833313 17.8741 0.899161 18.0331 1.01637 18.1503C1.13358 18.2675 1.29255 18.3334 1.45831 18.3334H18.125C18.2907 18.3334 18.4497 18.2675 18.5669 18.1503C18.6841 18.0331 18.75 17.8741 18.75 17.7084C18.75 17.5426 18.6841 17.3836 18.5669 17.2664C18.4497 17.1492 18.2907 17.0834 18.125 17.0834H1.45831Z" fill="#C6E4FF" />
                    </svg>

                    <p className="font-[montserrat] text-primary filter-item">{option_b}</p>
                </a>
            </div>
        </>
    );
}

export default TableChartToggle;