import { Helmet } from "react-helmet"
import Paginator from "../components/Paginator"
import { FaDownload } from "react-icons/fa"
import { useState } from "react"
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query"
import axios from "axios"
import ClipLoader from "react-spinners/ClipLoader"
import toast from "react-hot-toast"
import fileDownload from "js-file-download"
import mime from "mime"
import { useSelector } from "react-redux"

const queryClient = new QueryClient()
const HOST = process.env.REACT_APP_API_HOST

export default (props)=>{
    return <QueryClientProvider client={queryClient}>
        <BlogPage />
    </QueryClientProvider>
}

const BlogPage = (props)=>{
    const [currentPage, setCurrentPage] = useState(1)
    const token = useSelector((state)=>state.authToken.token)

    const url = HOST + `/api/blog/stories/list/${currentPage}`
    const { isLoading, error, data } = useQuery({
        queryKey: [url],
        queryFn: ()=>axios.get(url).then((res)=>{ return res.data }),
        retry: 3
    })

    const showContent = ()=>{
        if(isLoading){
            return <div style={{height: "30vh"}} className="flex justify-center items-center"><ClipLoader color="#034179" /></div>
        }else if(error || !data.status){
            return <p class="text-center my-[20px] font-Montserrat text-primary">Unable to retrieve data, try again in a moment</p>
        }

        return (
            <div>
                <div className="flex gap-[5%] max-[850px]:flex-col mt-[80px] max-sm:mt-[30px]">
                    <div className="w-[70%] max-[850px]:w-full mb-[30px]">
                        <h3 className="text-primary max-sm:text-[13pt] text-[18pt]  font-medium cursor-pointer hover:text-[#139DB8] leading-[40px] max-sm:leading-[30px]">
                            <a href={`/blog/story/${data.data.stories[0].id}`}>{ data.data.stories[0].title }</a>
                        </h3>
                        <p className="text-secondary font-Montserrat text-[10pt] font-semibold my-[30px]">
                            {formatDate(data.data.stories[0].created_at)}
                        </p>

                        <img className="w-full" src={`https://eptdashboard.kimpact.org.ng/storage${data.data.stories[0].image}`}/>

                        <div className="mt-[50px]">
                            { data.data.stories.slice(1).map((story)=><BlogItem key={story.id} id={story.id} title={story.title} postDate={story.created_at} imageURI={story.image}/>) }
                        </div>

                        { (data)? 
                            <div className="p-[5px] overflow-x-auto"><Paginator
                            currentPage={currentPage - 1}
                            onPageChange={(event)=>{setCurrentPage(event.selected + 1)}}
                            pageCount={data.data.total_num_pages}
                            /></div> : "" }
                    </div>
                    
                    <div className="w-[30%] max-[850px]:w-full">
                        <h3 className="text-primary text-[14pt] font-semibold mb-[20px]">Recent Stories</h3>
                        
                        <div className="max-[850px]:grid grid-rows-2 grid-cols-2 gap-[20px] max-[500px]:block">
                            { data.data.recent_stories.map((story)=><SidePaneItem id={story.id} title={story.title} postDate={story.created_at}/>) }
                        </div>
                    </div>                                            
                </div>    
            </div>
        )
    }

    return (
        <>
            <Helmet>
                <title>Blog - Kimpact</title>
            </Helmet>
                <div class="items-center justify-center px-[8%] min-[850px]:py-[50px]">
                    
                    <p className="font-Baskerville max-[850px]:text-[19pt] text-[24pt] text-center text-primary font-medium mt-[50px]">Blog</p>
                    
                    <div className="flex flex-col items-center">
                        <hr className="h-[2px] bg-[#0A2458] w-[20%] min-w-[150px] my-[20px]" />
                    </div>
                    
                    { showContent() }

                </div>
        </>
    )
}

const formatDate = (dateString)=>{
    return new Date(dateString).toLocaleDateString("en-US", {
        year: "numeric", 
        month: "long", 
        day: "numeric"
    })
}

const BlogItem = (props)=>{
    
    return (
        <>
            <div className="w-full flex max-sm:flex-col-reverse gap-[20px]">
                <div className="w-full">
                    <a href={`/blog/story/${props.id}`} className="cursor-pointer hover:text-[#139DB8] text-primary font-Baskerville text-[14pt] max-sm:text-[11pt] max-sm:leading-[30px] leading-[40px] hover:underline cursor-pointer">
                        {props.title}
                    </a>
                    <p className="text-secondary font-Montserrat text-[10pt] font-semibold mt-[10px]">
                        { formatDate(props.postDate) }
                    </p>
                </div>
                <div className="max-w-[400px]">
                    <img src={`https://eptdashboard.kimpact.org.ng/storage${props.imageURI}`}/>
                </div>
            </div>
            <hr className="h-[1.5px] bg-[#0A2458CC] w-full my-[40px]" />
        </>
    )
}

const SidePaneItem = (props)=>{
    return (
        <div className="mb-[15px] bg-[#f3f3f3] p-[15px]">
            <a href={`/blog/story/${props.id}`} className="max-sm:text-[10pt] cursor-pointer hover:underline text-[#139DB8]">{props.title}</a>
            <p className="text-[9pt] font-Montserrat text-primary mt-[5px] font-medium">{formatDate(props.postDate)}</p>
        </div>
    )
}