import { AnimatePresence, motion, useAnimationControls } from "framer-motion"
import React, {  useState, forwardRef, useImperativeHandle, useEffect } from "react"

let textLength = {}
let revealAnimationRef = {}
let hideAnimationRef = {}

const TextAnimator = forwardRef((props, ref) => {
    const text = props.text
    const key = text.length
    const [tl, setTl] = useState(key)

    if(textLength[key] == null){
        textLength[key] = key
    }

    useImperativeHandle(ref, ()=>({
        startAnimation,
        stopAnimation
    }))

    const startAnimation = ()=>{
        setTimeout(()=>{
            hideAnimation()
        }, 10000)
    }

    const stopAnimation = ()=>{
        clearInterval(revealAnimationRef[key])
        clearInterval(hideAnimationRef[key])
        textLength[key] = key
    }

    const hideAnimation = ()=>{
        hideAnimationRef[key] = setInterval(()=>{
            if(textLength[key] > 0){      
                setTl(textLength[key])
                textLength[key] += -1
            }else{
                clearInterval(hideAnimationRef[key])
                setTimeout(revealAnimation, 100)
            }
        }, 50)
    }

    const revealAnimation = ()=>{
        revealAnimationRef[key] = setInterval(()=>{
            if(textLength[key] < text.length){
                setTl(textLength[key])
                textLength[key] += 1
            }else{
                clearInterval(revealAnimationRef[key])
                setTimeout(hideAnimation, 10000)
            }
        }, 50)
    }

    useEffect(()=>{
        // console.log("component didMount")
        // startAnimation()
        // return ()=>{
        //     stopAnimation()
        //     // console.log("component unmounted")
        // }
    }, [])

    return (
        <div className="relative">
            <div className="opacity-0">{text}</div>
            <div className="absolute top-0">
                {text.substring(0, textLength[key])}
            </div>
        </div>
    )
})
export default TextAnimator