import Dropdown from "./Dropdown";
import { useSelector, useDispatch } from "react-redux"
import { setFilter, clearFilter } from "../features/PetitionFilterSlice"

const states = [
    {
        "state": "Abia"
    },
    {
        "state": "Adamawa"
    },
    {
        "state": "Akwa Ibom"
    },
    {
        "state": "Anambra"
    },
    {
        "state": "Bauchi"
    },
    {
        "state": "Bayelsa"
    },
    {
        "state": "Benue"
    },
    {
        "state": "Borno"
    },
    {
        "state": "Cross River"
    },
    {
        "state": "Delta"
    },
    {
        "state": "Ebonyi"
    },
    {
        "state": "Edo"
    },
    {
        "state": "Ekiti"
    },
    {
        "state": "Enugu"
    },
    {
        "state": "FCT"
    },
    {
        "state": "Gombe"
    },
    {
        "state": "Imo"
    },
    {
        "state": "Jigawa"
    },
    {
        "state": "Kaduna"
    },
    {
        "state": "Kano"
    },
    {
        "state": "Katsina"
    },
    {
        "state": "Kebbi"
    },
    {
        "state": "Kogi"
    },
    {
        "state": "Kwara"
    },
    {
        "state": "Lagos"
    },
    {
        "state": "Nasarawa"
    },
    {
        "state": "Niger"
    },
    {
        "state": "Ogun"
    },
    {
        "state": "Ondo"
    },
    {
        "state": "Osun"
    },
    {
        "state": "Oyo"
    },
    {
        "state": "Plateau"
    },
    {
        "state": "Rivers"
    },
    {
        "state": "Sokoto"
    },
    {
        "state": "Taraba"
    },
    {
        "state": "Yobe"
    },
    {
        "state": "Zamfara"
    }
]

const PetitionFilters = (props)=>{

    const dispatch = useDispatch()

    const electionFilters = ["No Filter", "Presidential", "Senatorial", "House of Reps", "Governorship", "State House of Assembly"];
    const stageFilters = ["No Filter", "Pre-Hearing", "Hearing", "Judgement"];
    const courtFilters = ["No Filter", "Tribunal", "Court of Appeal", "Supreme Court"];
    const stateFilters = ["No Filter"].concat(states.map((s)=>s["state"]));
    const updateFilter = (filter, value)=>{
        if(value === "No Filter"){
            // console.log("removing a filter");
            return dispatch(clearFilter({key: props.fid, filter: filter, value: value }))
        }
        dispatch(setFilter({key: props.fid, filter: filter, value: value }))
    }

    return (
        <>
            <div className="h-full flex justify-between px-searchbar_padding gap-[10px] bg-[#EBF5FF] items-center shadow-[0px_0px_15px_#c6E4FF7F] border-[#2D9BFF40] border-[1px] rounded-full">
                <Dropdown onChange={(value)=>{updateFilter("election_type", value)}} title="Election" menuItems={electionFilters}/>
                <Dropdown onChange={(value)=>{updateFilter("stage", value)}} title="Stage" menuItems={stageFilters}/>
                <Dropdown onChange={(value)=>{updateFilter("state", value)}} title="State" menuItems={stateFilters}/>
            </div>
        </>
    );
}

export default PetitionFilters;