
import { useState, useEffect, CSSProperties } from "react"
import Paginator from "../components/Paginator"
import "../styles/Table.css"
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios"
import {QueryClient, QueryClientProvider, useQuery} from "@tanstack/react-query"
import toast from "react-hot-toast";
import fileDownload from "js-file-download"
import mime from "mime"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

const queryClient = new QueryClient()
const host = process.env.REACT_APP_API_HOST
const defaultColumns = ["S/N", "Petition No.", "Petitioners", "Respondents", "State", "Tribunal Verdict", "Election Type", ""]

export default (props)=>{
    return (
        <QueryClientProvider client={queryClient}>
            <Table url={props.url} columns={props.columns} dataProcessor={props.dataProcessor} queryParams={props.queryParams} queryKey={props.queryKey}/>
        </QueryClientProvider>
    )
}

const Table = ({url, queryKey, columns=defaultColumns, dataProcessor=null, queryParams})=>{
    const [currentPage, setCurrentPage] = useState(1)
    const {isLoading, error, data} = useQuery({
        queryKey: [url + queryParams + currentPage],
        queryFn: ()=>axios.get(`${url}/${currentPage}?${queryParams}`).then((data)=>data.data),
        retry: 3
    })
    const token = useSelector((state)=>state.authToken.token)
    const navigate = useNavigate()

    const downloadLinkHandler = (url)=>{
        axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res)=>{
            if(res.status == 500){
                toast.error("Sorry, we cannot process your request at the moment, please try again later")
                return
            }
            if(res.status == 401){
                toast.error("Access Denied, login required to download file")
                return
            }
            if(!res.data.status){
                toast.error(res.data.error_message)
                return
            }
            const authToken = res.data.data.token
            const downloadLink = url + `/authorization?authorization=${authToken}`
            downloadFile(downloadLink)
        }).catch((error)=>{
            try{
                if(error.response.status == 401){
                    toast.error("Access Denied, login required to download file")
                    return
                }
            }catch(err){
                // just ignore the error
            }            
            toast.error(error.message)
        })
        toast("Download will start in a moment...", {position: "bottom-left", duration: 2000})
    }

    const downloadFile = (url)=>{
        // axios.get(url).then((res)=>{
        //     if(res.status == 500){
        //         toast.error("Sorry, we cannot process your request at the moment, please try again later")
        //         return
        //     }
        //     if(res.headers["content-type"] == "application/json"){
        //         if(!res.data.status){
        //             toast.error(res.data.data.message)
        //             return
        //         }
        //         toast.error("Sorry, your file could not be downloaded")
        //         return
        //     }
        // }).catch((error)=>{
        //     toast.error(error.message)
        // })
        
        // create anchor element to trigger the download
        const anchor = document.createElement("a")
        anchor.href = url
        
        // invoke the download
        anchor.click()

        URL.revokeObjectURL(url)
    }


    const tableContent = ()=>{
        if(error || !data.status){
            return <p class="text-center mt-[20px] font-Montserrat text-primary">Unable to retrieve data, will try again in a moment</p>
        }
        // since some are petitions and other appeals
        // a better solution will be to use the key log generally instead of petitions|appeals 
        // this solution will however break the current dashboard (using this as a temporary solution)
        if((data.data.petitions == undefined)? data.data.appeals.length < 1 : data.data.petitions.length < 1){
            return <p class="text-center mt-[20px] font-Montserrat text-primary">No data at the moment</p>
        }
        return (
            <div className="min-w-[850px] flex justify-center mt-[50px]">
                <Paginator onPageChange={(event)=>{ setCurrentPage(event.selected + 1);}} pageCount={data.data.totalPages} currentPage={currentPage - 1}/>
            </div>
        )
    }
    
    const defaultProcessor = (data)=>{
        return data.data.petitions.map((value, index)=>{
            return <tr className="pt-[10px] pb-[10px]">
                        <td>{value.sn}. </td>
                        <td>{value.petition_number}</td>
                        <td>{value.petitioners_name}</td>
                        <td>{value.respondent_pol}</td>
                        <td>{value.state}</td>
                        <td>{value.judgement}</td>
                        <td>{value.election_type}</td>
                        <td className="text-tertiary">
                            <a className="cursor-pointer" onClick={()=>{downloadLinkHandler(host + `/api/petitions/download/${value.id}`, value.petition_number)}}>
                                Download
                            </a>
                        </td>
                    </tr>
        })
    }

    dataProcessor = (dataProcessor == null)? defaultProcessor : dataProcessor;

    const tableColumns = columns.map((col)=><th key={Math.random() + "_" + col}>{col}</th>)

    return (
        <>
            <div style={{textAlign: "-webkit-center"}} className="Table overflow-x-auto pb-[20px]">
                <table className="min-w-[850px]">
                    <thead>
                        <tr>
                            {tableColumns}
                        </tr>
                    </thead>

                    <tbody>
                        {(isLoading || error || !data.status)? "" : dataProcessor(data)}
                    </tbody>
                </table>

                {(isLoading)? <div style={{height: "60vh"}} className="flex justify-center items-center"><ClipLoader color="#034179" /></div> : tableContent()}
            </div>
        </>
    )

}
