import { useSelector, useDispatch } from "react-redux"
import { setFilter, clearFilter } from "../features/PetitionFilterSlice"
import { FaSearch, FaTimes } from "react-icons/fa"
import { useState, useRef } from "react"

const SearchBar = (props)=>{
    const { onClick=()=>{ } } = props
    const dispatch = useDispatch()
    const searchInput = useRef()
    const filterParams = useSelector((state)=>state.filter.value)

    let searchText = null
    if(props.fid == "petitions"){
        searchText = (filterParams.petitions["search"])? filterParams.petitions["search"] : ""
    }else{
        searchText = (filterParams.judgements["search"])? filterParams.judgements["search"] : ""
    }
    const [searchValue, setSearchValue] = useState(searchText)

    const onSearch = (query)=>{
        if(query == ""){
            return dispatch(clearFilter({
                key: props.fid,
                filter: "search"
            }))
        }
        dispatch(setFilter({
            key: props.fid,
            filter: "search",
            value: query,
        }))
    }

    return (
        <>
            <div onClick={()=>{onClick()}} className="cursor-pointer h-full flex gap-[10px] px-searchbar_padding items-center shadow-[0px_0px_15px_#c6E4FF7F] border-[#2D9BFF40] border-[1px] rounded-full">
                <FaSearch className="text-[15pt] text-primary"/>
                <input
                    ref={searchInput}
                    onKeyDown={(e)=>{
                        if(e.key == "Enter"){
                        onSearch(e.target.value); 
                        }                    
                    }}
                    onChange={(e)=>{
                        setSearchValue(e.target.value)
                    }}
                    type="text"
                    value={searchValue}
                    placeholder="Search"
                    className="w-full outline-none font-[montserrat] filter-item"/>
                
                {
                    (searchValue.length > 0)?
                    <FaTimes onClick={()=>{
                        searchInput.current.value = ""
                        setSearchValue("")
                        onSearch(searchInput.current.value)
                    }} className="text-[12pt] text-[#999999] text-primary"/>
                    : ""
                }
            </div>
        </>
    );
}

export default SearchBar;