import { createSlice } from "@reduxjs/toolkit"

const getToken = ()=>{
    const tokenString = sessionStorage.getItem("token")        
    const userToken = JSON.parse(tokenString)
    return userToken?.access_token
}

export const authTokenSlice = createSlice({
    name: "authToken",
    initialState: {
        token: getToken(),
    },
    reducers: {
        setAuthToken: (state, action)=>{
            let userToken = action.payload.token
            sessionStorage.setItem("token", JSON.stringify(userToken))
            state.token = userToken.access_token
        },
        clearAuthToken: (state)=>{
            sessionStorage.removeItem("token")
            state.token = null
        }
    }
})

export const { setAuthToken, clearAuthToken } = authTokenSlice.actions
export default authTokenSlice.reducer