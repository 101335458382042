import SearchBar from "./SearchBar";
import TableChartToggle from "./TableChartToggle";
import CalendarFilter from "./CalendarFilter";
import PetitionFilter from "./PetitionFilters";
import "../../styles/SearchFilterBar.css";
import { useState } from "react"
const SearchFilterBar = (props)=>{

    const [isSearchCollapsed, setIsSearchCollapsed] = useState(true)

    /**
     * When the search bar is called, change the state of the search bar to full,
     * When another 
     */

    const searchBarClickHandler = ()=>{
        setIsSearchCollapsed(false)
    }
    
    const collapseSearch = ()=> {
        setIsSearchCollapsed(true)
    }

    return (
        <>
            <div className="SearchFilterBar">
                <div style={{width: (isSearchCollapsed)? "300px" : "100%" }} className=" max-lg:hidden transition-all transition-[2000] filterItem h-searchbar">
                    <SearchBar fid={props.fid} onClick={searchBarClickHandler} />
                </div>

                <div style={{width: (!isSearchCollapsed)? "180px" : "100%" }} className=" max-lg:hidden overflow-hidden transition-all transition-[2000] filterItem w-[100%] h-searchbar">
                    <CalendarFilter fid={props.fid} collapsed={!isSearchCollapsed} onCalendarClick={collapseSearch} />
                </div>
                
                <div className="filterItem w-[100%] h-searchbar">
                    <PetitionFilter fid={props.fid} />
                </div>
            </div>

            <div className="mt-[30px] flex justify-center">
                <div className="filterItem h-[50px]">
                    <TableChartToggle onToggle={props.toggleContent} isTable={props.isTable} option_b={(props.fid == "petitions")? "Charts" : "Map"}/>
                </div>
            </div>
        </>
    );
}

export default SearchFilterBar;