import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Navigation, Pagination, Mousewheel } from 'swiper/modules';



export default function App(props) {

  const swiperRef = useRef()

  props.addListener((index)=>{
    // Check if the swiper instance exists
    if (swiperRef.current && swiperRef.current.swiper) {
      // Use the slideTo method to go to the desired page
      swiperRef.current.swiper.slideTo(index);
    }
  })

  const goToPage = (index) => {
    
  };

  return (
    <>
      <Swiper
        noSwiping={true}
        noSwipingClass='no-swiping'
        ref={swiperRef}
        freeMode={true}
        direction={'vertical'}
        pagination={{
          clickable: true,
        }}
        modules={[Mousewheel]}
        className="h-[89vh] w-full"
      >
        {React.Children.map(props.children, child=><SwiperSlide className="no-swiping">{child}</SwiperSlide>)}
      </Swiper>
    </>
  );
}