import ReactPaginate from "react-paginate"

const Paginator = (props)=>{
    return (
        <div className="Paginator justify-center">
            <ReactPaginate
                forcePage={props.currentPage}
                breakLabel="..."
                nextLabel="Next"
                onPageChange={props.onPageChange}
                pageCount={props.pageCount}
                pageRangeDisplayed={5}
                previousLabel="Previous"
                renderOnZeroPageCount={null} />
        </div>
    )
}

export default Paginator