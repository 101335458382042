import PetitionsPage from "./Petitions";
import JudgementsPage from "./Judgements";
import FilterDialog from "../components/FilterDialog"
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { useState, useEffect, Component, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { Helmet } from "react-helmet"
import Carousel from "../components/Carousel"
import { useWindowScroll } from "@react-hook/window-scroll"
import Footer from "../components/Footer"
import * as Icons from "react-icons/fa"
import TextAnimator from "../components/TextAnimator"

const Home = ()=>{
    const textAnimatorRef = useRef()

    const [show, setShow] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const [showFab, setShowFab] = useState(false)
    const navigate =  useNavigate()

    // const {left, top} = useWindowScroll()

    let landingPageRef
    let petitionsPageRef
    let judgementPageRef

    const updatePagesPos = ()=>{
        landingPageRef = document.getElementById("landing_page")
        petitionsPageRef = document.getElementById("PetitionsPageContainer")
        judgementPageRef = document.querySelector(".JudgementsPage")
    }

    let listener = null

    let changePage = (newPage)=>{
        listener(newPage)
        setCurrentPage(newPage)
    }

    const addListener = (func)=>{
        listener = func
    }
    
    useEffect(()=>{
        
    }, [])

    return (
        <>
        <Box sx={{right: "25px", maxWidth: "180px"}} className="transition-all fixed z-[10] bottom-[50px]">
			<Fab className="FabButton" onClick={()=>{navigate("/world-data")}} sx={{textTransform: "capitalize", background: "#034179", padding: "10px"}} variant="extended">
				<img src={process.env.PUBLIC_URL + "/assets/icons/globe.svg"}/>
				<p className="font-Montserrat ml-[10px] text-[9pt] leading-[14px] text-white">Africa Electoral Justice Data</p>
			</Fab>
		</Box>

            <Helmet>
                <title>Home - EPT Dashboard</title>
            </Helmet>
            {(show)? <FilterDialog filterId={(currentPage == 2)? "judgements" : "petitions" } onClose={()=>{ setShow(false) }}/> : <></>}
            
            <Carousel addListener={addListener}>
                <div id="landing_page" className="overflow-x-hidden overflow-y-auto bg-primary bg-bkg_city object-contain w-full h-full p-12 lg:align-middle border-white border-solid lg:gap-10 lg:flex lg:p-30" >
                    <div className="flex flex-col items-center gap-8 lg:flex lg:flex-row-reverse md:gap-[20px] md:align-middle lg:justify-around lg:ml-[72px]">
                        {/* Landing page items container */}
                        <div className="w-full flex justify-center">
                            <img className="object-contain w-[300px] lg:min-w-[300px] lg:grow" src="/assets/img/Judge Hammer.png" alt="Judge hammer" />
                        </div>

                        <div className="w-full flex flex-col justify-start items-center gap-4 md:w-[1/3] md:gap-5 md:max-lg:px-[5%]">
                            {/* texts and buttons */}
                            <p className="font-Baskerville lg:max-xl:text-[33px] max-xl:leading-[47px] max-lg:text-center max-lg:text-[20pt] text-4xl  text-[#B8FFA1] lg:text-left lg:text-[43px] font-semibold w-full leading-[75px] max-sm:leading[30px] max-sm:text-[16pt]" style={{}}>
                                <TextAnimator ref={textAnimatorRef} duration={0.2} text={"Nigeria Election Petition Tribunal Monitoring Dashboard"}/>
                            </p> 
                            <p className="grow font-Baskerville text-[12pt] text-white lg:text-[12pt] max-lg:text-center lg:text-left lg:mb-[30px] leading-[35px] max-sm:leading-[30px] max-sm:mb-[20px] max-sm:text-[10pt]"> 
                                A hub for data on the Election Petitions filed across Nigeria, and the Judgements from the proceedings of the Tribunals.
                            </p>
                            <div className="flex flex-col gap-8 md:flex-row lg:self-start">
                                <button onClick={()=>{changePage(1); setShowFab(true)}} className="bg-white text-primary_light rounded-[50px] font-Montserrat text-[10pt]  font-bold w-[200px] h-[55px]">Petitions</button>
                                <button onClick={()=>{changePage(2); setShowFab(true)}} className="bg-high_ctrst text-primary rounded-[50px] font-Montserrat text-[10pt] font-bold w-[200px] h-[55px]">Judgements</button>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div id="PetitionsPageContainer" className="h-full overflow-x-hidden overflow-y-auto">                    
                    <PetitionsPage />
                    <p onClick={()=>{changePage(2); setShowFab(true)}} style={{rotate: "-90deg", left: "-42px", borderRadius: "0px 0px 10px 10px", bottom: "280px"}} className="flex items-center justify-center font-semibold cursor-pointer absolute w-[135px] py-[15px] px-[20px] text-[8pt] text-white bg-[#0D7C607F] rounded-[0px_20px_20px_0px] font-Montserrat">
                        <div className="p-[5px] mr-2" style={{rotate: "90deg"}}><Icons.FaArrowDown/></div> Judgements
                    </p>
                    <p onClick={()=>{changePage(0); setShowFab(false)}} style={{rotate: "-90deg", left: "-38px", borderRadius: "0px 0px 10px 10px", color: "black"}} className="flex items-center text-black font-semibold justify-center cursor-pointer absolute bottom-[135px]  w-[125px] py-[15px] px-[20px] text-[8pt] text-white bg-[#80E0617F] rounded-[0px_20px_20px_0px] font-Montserrat">
                        Home <div className="p-[5px]" style={{rotate: "90deg"}}><Icons.FaHome/></div>
                    </p>
                </div>

                <div id="JudgementPageContainer" className="h-full overflow-x-hidden overflow-y-auto">
                    <JudgementsPage />
                    <Footer />                    
                    <p onClick={()=>{changePage(1); setShowFab(true)}} style={{rotate: "-90deg", left: "-32px", borderRadius: "0px 0px 10px 10px"}} className="flex items-center justify-center font-semibold cursor-pointer absolute bottom-[250px] w-[115px] py-[15px] px-[20px] text-[8pt] text-white bg-[#0341797F] rounded-[0px_20px_20px_0px] font-Montserrat">
                        Petitions <div className="p-[5px] ml-2" style={{rotate: "90deg"}}><Icons.FaArrowUp/></div>
                    </p>
                    <p onClick={()=>{changePage(0); setShowFab(false)}} style={{rotate: "-90deg", left: "-32px", borderRadius: "0px 0px 10px 10px", color: "black"}} className="flex items-center text-black font-semibold justify-center cursor-pointer absolute bottom-[125px]  w-[115px] py-[15px] px-[20px] text-[8pt] text-white bg-[#80E0617F] rounded-[0px_20px_20px_0px] font-Montserrat">
                        Home <div className="p-[5px] ml-2" style={{rotate: "90deg"}}><Icons.FaHome /></div>
                    </p>
                </div>

            </Carousel>

            {(showFab)? <div className="lg:hidden">
                <Fab className="FabButton" onClick={()=>{ setShow(true) }} aria-label="add" sx={{ background: "#034179", position: "fixed", bottom: 120, right: 25}}>
                    <ion-icon name="filter-outline" style={{color: "#ffffff", }}></ion-icon>
                </Fab>
            </div> : ""}
        </>
    )
}

export default Home;