import { useState, useEffect } from "react"
import SearchFilterBar from "../components/filterbar/SearchFilterBar"
import HeatMap from "../components/charts/HeatMap"
import BarChart from "../components/charts/BarChart"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import HighChartMap from "../components/charts/HighChartMap"
import SearchBar from "../components/filterbar/SearchBar"
import CalendarFilter from "../components/filterbar/CalendarFilter"
import PetitionFilter from "../components/filterbar/PetitionFilters"
import Table from "../components/Table"
import "../styles/Petition.css"
import "../styles/Judgements.css"
import "../styles/SearchFilterBar.css"
import {QueryClient, QueryClientProvider, useQuery} from "@tanstack/react-query"
import axios from "axios"
import ClipLoader from "react-spinners/ClipLoader"
import { useSelector } from "react-redux"
import toast from 'react-hot-toast'
import Tooltip from "@mui/material/Tooltip"

const queryClient = new QueryClient()

export default ()=>{
    return (
        <QueryClientProvider client={queryClient}>
            <JudgementsPage />
        </QueryClientProvider>
    )
}

const JudgementsPage = (props)=>{
    const filterParams = useSelector((state)=>state.filter.value.judgements)
    const [queryParams, setQueryParams] = useState(filterParams)
    const [query, setPetitionsQuery] = useState("")

    const [tableIndex, setTableIndex] = useState(0)
    const [isTable, setIsTable] = useState(false)

    const {isLoading, error, data} = useQuery({
        queryKey: ["judgements" + query],
        queryFn: ()=>axios.get(process.env.REACT_APP_API_HOST + "/petitions/map/filter?stage=judgement&" + query).then((data)=>data.data.data),
        retry: 3
    })

    useEffect(()=>{
        let params = JSON.stringify(filterParams)
        if(params != queryParams){
            let q = ""
            for(let key in filterParams){
                if(key == "court" || key == "year" || key == "stage"){
                    continue
                }else if(key == "search"){
                    q += `q=${filterParams[key]}&`
                    continue
                }
                q += `${key}=${filterParams[key]}&`
            }
            setQueryParams(params)
            setPetitionsQuery(q)
            if(q.length <= 0){
                return
            }
        }
    })

    const switchContent = (isTable)=>{
        setIsTable(isTable)
    }

    const map = ()=>{
        if(error){
            return <div className="text-center font-Montserrat">
                <p>Unable to load map at the moment</p>
            </div>
        }
        return (
            <>
                <div className="min-[850px]:min-w-[500px] pt-[20px] pb-[50px] px-[20px] bg-[#F0F8FF] justify-center items-center shadow-[0px_0px_5px_#c6E4FF19] border-[#2D9BFF40] border-[1px] rounded-[20px]">
                    
                    {(isLoading)? <div className="w-full text-center"><ClipLoader color="#034179" /></div> : <HighChartMap title="Disaggregation of Judgements per State" data={data}/>}
                    
                </div>
            </>
        )
    }

    const appealColumns = [
        "S/N",
        "Appeal No.",
        "Appellants",
        "Respondents", 
        "State",
        "Judgement",
        "Election Type",
    ]

    const appealDataProcessor = (data)=>{
        return data.data.appeals.map((value, index)=>{
            return <tr className="pt-[10px] pb-[10px]">
                        <td>{value.sn}. </td>
                        <td>{(value.appeal_number)? value.appeal_number : "N/A"}</td>
                        <td>{value.appellants}</td>
                        <td>{value.respondents}</td>
                        <td>{value.state}</td>
                        <td>{value.judgement}</td>
                        <td>{value.election_type}</td>
                    </tr>
        })
    }

    const tables = [
        <Table key={"tribunal_petitions"} queryKey="tribunalJudgements" url={process.env.REACT_APP_API_HOST + "/petitions/judgements/filter"} queryParams={query} />,
        <Table key={"appeal_judgements"} columns={appealColumns} dataProcessor={appealDataProcessor} queryKey="appealJudgements" url={process.env.REACT_APP_API_HOST + "/api/appeals/coa/filter"} queryParams={query}/>,
        <Table key={"supreme_court"} columns={appealColumns} dataProcessor={appealDataProcessor} queryKey="supremeJudgements" url={process.env.REACT_APP_API_HOST + "/api/appeals/sc/filter"} queryParams={query}/>,
    ]

    const table = <>
       <div className="justify-center">
            <div className="justify-center items-center">
                <div className="w-full mb-[40px] flex justify-center gap-[20px] items-center">
                    <a onClick={()=>{setTableIndex(0)}} className={["font-Montserrat", "cursor-pointer", "filter-item", (tableIndex == 0)? "item-active" : ""].join(" ")}>
                        <Tooltip title="Tribunal" arrow>
                            {
                                (tableIndex == 0)? 
                                <span>Tribunal</span> : 
                                <> 
                                    <span className="max-[450px]:hidden">Tribunal</span>
                                    <span className="min-[450px]:hidden">EPT</span>
                                </>
                            }
                        </Tooltip>
                    </a>
                    <a onClick={()=>{setTableIndex(1)}} className={["font-Montserrat", "cursor-pointer", "filter-item", (tableIndex == 1)? "item-active" : ""].join(" ")}>
                        <Tooltip title="Court of Appeal" arrow>
                            {
                                (tableIndex == 1)?
                                <span>Court of Appeal</span> : 
                                <> 
                                    <span className="max-[450px]:hidden">Court of Appeal</span> 
                                    <span className="min-[450px]:hidden">COA</span>
                                </>
                            }
                        </Tooltip>
                    </a>
                    <a onClick={()=>{setTableIndex(2)}} className={["font-Montserrat", "cursor-pointer", "filter-item", (tableIndex == 2)? "item-active" : ""].join(" ")}>
                        <Tooltip title="Supreme Court" arrow>
                            {
                                (tableIndex == 2)?
                                <span>Supreme Court</span> : 
                                <> 
                                    <span className="max-[450px]:hidden">Supreme Court</span>
                                    <span className="min-[450px]:hidden">SC</span>
                                </> 
                            }                        
                        </Tooltip>
                    </a>
                </div>
                {tables[tableIndex]}
            </div>
        </div>
    </>

    
    return (
        <div className="JudgementsPage py-[40px] px-[5%]">
            <>
                <SearchFilterBar fid="judgements" toggleContent={switchContent} isTable={isTable} />
            </>

            <div className="py-[50px] gap-[30px]">
                {(isTable)? table : map()}
            </div>
        </div>
    );
}