import { useState, useRef } from "react"
import axios from "axios"
import { FaCircleNotch } from "react-icons/fa"
import toast from "react-hot-toast"
import Alert from "@mui/material/Alert"
import {  } from "react-router-dom"

const host = process.env.REACT_APP_API_HOST

const Footer = () => {
    const [subKimpactUpdates, setSubKimpactUpdates] = useState(false)
    const [subEPTUpdates, setSubEPTUpdates] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [errors, setErrors] = useState([])
    const emailRef = useRef()

    const subscribeNewsletter = ()=>{
        const url = host + "/api/newsletter/subscribe"
        if(subKimpactUpdates || subEPTUpdates){
            setIsProcessing(true)
            axios.post(url, {
                "email": emailRef.current.value
            }).then((res)=>{
                setErrors([])
                if(!res.data.status){
                    setErrors(res.data.error_description.errors)
                    return toast.error(res.data.error_message)
                }
                if(res.status === 500){
                    return toast.error("Unable to process request, please try again later")
                }
                return toast.success("Subscription Successful", {position: "top-center"})
            }).catch((error)=>{
                toast.error(error.message, {hideProgressBar: true})
            }).finally(()=>{
                setIsProcessing(false)
            })
        }
    }

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <>
            <div id="footer" class="flex flex-col items-center justify-center pt-[60px] gap-[70px] sm:flex-row sm:w-full sm:pl-[8%] sm:pr-[8%] pb-[60px]">
                <div name="SingUp_Subscription" class="flex flex-col gap-[12px] w-2/3">
                    <p class="font-Baskerville text-primary_light text-[20px] text-center sm:text-left self-start">
                        Sign up to the Election Petition Tribunal Dashboard
                    </p>

                    {errors.map((error)=><div className="mb-2"><Alert variant="filled" severity="error">{error}</Alert></div>)}

                    <div class="flex">
                        <div class="flex items-center h-5">
                            <input id="helper-checkbox" aria-describedby="helper-checkbox-text" type="checkbox" onClick={()=>{setSubKimpactUpdates(!subKimpactUpdates)}} class="w-4 h-4 text-primary bg-gray-100 border-gray-300 rounded focus:bg-primary_light dark:focus:bg-secondary dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                        </div>
                        <div class="ms-2 text-sm">
                            <label for="helper-checkbox" class="font-Montserrat text-[13px] font-bold text-gray-700 dark:text-gray-300">Subscribe to KIMPACT newsletter</label>
                            <p id="helper-checkbox-text" class="text-xs font-Montserrat font-normal text-gray-500 dark:text-gray-300">Receive daily and weekly updates from KIMPACT Development Initiative </p>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="flex items-center h-5">
                            <input id="helper-checkbox" aria-describedby="helper-checkbox-text" type="checkbox" onClick={()=>{setSubEPTUpdates(!subEPTUpdates)}} class="w-4 h-4 text-primary bg-gray-100 border-gray-300 rounded focus:bg-primary_light dark:focus:bg-secondary dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                        </div>
                        <div class="ms-2 text-sm">
                            <label for="helper-checkbox" className="font-Montserrat text-[13px] font-bold text-gray-700 dark:text-gray-300">Receive Bi-weekly updates</label>
                            <p id="helper-checkbox-text" className="text-xs font-Montserrat font-normal text-gray-500 dark:text-gray-300">Receive updates on the Election Petition Tribunals in Nigeria </p>
                        </div>
                    </div>

                    <div class="flex flex-col border-solid border-2 border-primary rounded-[10px] p-[5px] gap-3 sm:flex sm:flex-row">
                        <input ref={emailRef} id="email_input" aria-label="emailhelpertext" className="rounded-[10px] bg-primary/5 p-[10px] text-xs font-Montserrat font-normal text-[black] sm:w-3/4" type="email"  placeholder="youremail@example.com" />
                        {
                            (isProcessing)?
                            <button class="bg-high_ctrst flex items-center justify-center text-primary rounded-[10px] font-Montserrat text-[10px] font-bold sm:w-1/4 h-[30px] sm:h-auto"><FaCircleNotch className="fa-spin"/></button>
                            :
                            <button onClick={subscribeNewsletter} style={{ backgroundColor: (subKimpactUpdates || subEPTUpdates)? "#80E061" : "lightgray" }} class="rounded-[10px] font-Montserrat text-primary text-[10px] font-bold sm:w-1/4 h-[30px] sm:h-auto">Sign Up</button>  
                        }
                    </div>
                </div>
                <hr name="divider_line" class="border-solid border-primary/10 border-1 w-3/4 sm:hidden" />
                <div name="addresses_section" class="flex flex-col gap-[15px] grow sm:grow">
                    <div name="KDI Follow" class="flex flex-col">
                        <p class="font-Baskerville text-1xl text-primary">Follow KIMPACT</p>

                        <div class="flex flex-row gap-[13px] w-[16px] pt-[10px]">
                            <img class="cursor-pointer" onClick={ ()=>{ openInNewTab('https://www.instagram.com/kdi_ng/') }} src="/assets/img/Instagram_Icon.svg" alt="Instagram" />
                            <img class="cursor-pointer" onClick={ ()=>{ openInNewTab('https://www.linkedin.com/company/kimpact-development-initiative-kdi') }} src="/assets/img/Linked_In_Icon.svg" alt="Linked_In" />
                            <img class="cursor-pointer" onClick={ ()=>{ openInNewTab('https://x.com/KDI_ng') }} src="/assets/img/twitter_icon.svg" alt="twitter" />
                            <img class="cursor-pointer" onClick={ ()=>{ openInNewTab('https://web.facebook.com/kdinigeria') }} src="/assets/img/facebook_icon.svg" alt="facebook" />
                            <img class="cursor-pointer" onClick={ ()=>{ openInNewTab('https://www.youtube.com/@kimpactv') }} src="/assets/img/youtube_icon.svg" alt="youtube" />
                        </div>
                    </div>

                    <div name="addresses" class="flex flex-col gap-[15px]">
                        <div>
                            <p class="font-Montserrat font-bold text-[11px] text-primary_light">Email</p>
                            <p class="font-Montserrat font-medium text-[11px] text-primary_light">info@kimpact.org.ng</p>
                        </div>
                        <div>
                            <p class="font-Montserrat font-bold text-[11px] text-primary_light">Phone:</p>
                            <p class="font-Montserrat font-medium text-[11px] text-primary_light">+2348103947690</p>
                            <p class="font-Montserrat font-medium text-[11px] text-primary_light">+2349020118336</p>
                        </div>
                    </div>
                    <p name="Copyright" class="font-Montserrat font-medium text-[11px] text-primary_light">
                        Copyright © {new Date().getFullYear()} KDI - All Rights Reserved
                    </p>
                </div >
            </div >
        </>
    );
}

export default Footer;