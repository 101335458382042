import React from 'react'
import { render } from 'react-dom'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
require('highcharts/modules/map')(Highcharts);


// get the map data
const topology = await fetch(
  'https://code.highcharts.com/mapdata/countries/ng/ng-all.topo.json'
).then(response => response.json()).catch((error)=>{
  console.error("unable to fetch map data")
});



const App = (props) => {

  const mdata = [
    ['ng-ri', 7], ['ng-kt', 67], ['ng-so', 54], ['ng-za', 34],
    ['ng-yo', 2], ['ng-ke', 9], ['ng-ad', 21], ['ng-bo', 23],
    ['ng-ak', 24], ['ng-ab', 12], ['ng-im', 43], ['ng-by', 78],
    ['ng-be', 67], ['ng-cr', 78], ['ng-ta', 56], ['ng-kw', 32],
    ['ng-la', 9], ['ng-ni', 34], ['ng-fc', 90], ['ng-og', 23],
    ['ng-on', 2], ['ng-ek', 12], ['ng-os', 11], ['ng-oy', 12],
    ['ng-an', 2], ['ng-ba', 17], ['ng-go', 45], ['ng-de', 45],
    ['ng-ed', 5], ['ng-en', 34], ['ng-eb', 67], ['ng-kd', 23],
    ['ng-ko', 23], ['ng-pl', 45], ['ng-na', 89], ['ng-ji', 56],
    ['ng-kn', 65]
  ]

  const {data = mdata} = props
 
  
  const mapOptions =  {
    chart: {
        map: topology,
        zoomType: "x",
        events: {
          selection: (event)=>{
            event.preventDefault()
          }
        }
    },
  
    title: {
      text: props.title,
      style: {
        color: '#034179',
        fontSize: '9pt',
        fontWeight: '700',
        fontFamily: 'Montserrat',
      }
    },
  
    mapNavigation: {
        enabled: true,
        buttonOptions: {
            verticalAlign: 'bottom'
        }
    },
  
    colorAxis: {
        min: 0,
        type: 'linear',
        minColor: '#EEEEFF',
        maxColor: '#034179',
        stops: [
            [0, '#b5d8f8'],
            [0.67, '#2578c4'],
            [1, '#034179']
        ]
    },
  
    series: [{
        data: data,
        name: 'Number of Petitions',
        states: {
            hover: {
                color: '#034179'
            }
        },
        dataLabels: {
            enabled: true,
            format: '{point.name}'
        }
    }]
  }

  return (
    <>
       <div>
          <HighchartsReact
            constructorType={"mapChart"}
            highcharts={Highcharts}
            options={mapOptions}
          />
        </div>
    </>
  )
}



export default App