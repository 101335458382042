import { useState, useRef } from "react"
import { Helmet } from "react-helmet";
import { useNavigate, Link } from "react-router-dom";
import Alert from "@mui/material/Alert"
import * as Icons from "react-icons/fa"
import axios from "axios"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { setAuthToken } from "../components/features/AuthTokenSlice"

const host = process.env.REACT_APP_API_HOST

const Signin = (props) => {
    const token = useSelector((state)=>state.authToken.token)
    const dispatch = useDispatch()
    
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    let navigate = useNavigate()

    let emailRef = useRef()
    let passwordRef = useRef()

    let attempLogin = ()=>{
        setLoading(true)
        const url = host + "/api/auth/login"
        axios.post(url, {
            "email": emailRef.current.value,
            "password": passwordRef.current.value
        }, {
            headers: {
                Accept: "application/json"
            }
        }).then((data)=>{
            if(!data.data.status){
                setError(data.data.error_message)
                return
            }
            setError(null)
            dispatch(setAuthToken({
                token: data.data.credentials
            }))
            toast.success("Login Successful")
            setTimeout(()=>{
                navigate("/")
            }, 1000)
        }).catch((err)=>{
            if(err.response){
                setError(err.response.data.message)
                return
            }
            setError(err.message)
        }).finally(()=>{
            setLoading(false)
        })
    }

    return (
        <>
        <Helmet>
            <title>Sign In - Kimpact</title>
        </Helmet>
            <div className="Sign_Up_body">
                <div name="Sign_Up_Page_Content" className="Sign_Up_Page_Body">
                    <p className="w-full font-Baskerville text-high_ctrst md:text-left dual_flex_width text-center text-[18pt] leading-[35px] max-sm:text-[13pt]">
                        Welcome back to the 
                        Election Petition Tribunal Citizen Dashboard (EPT Citizen Dashboard)
                    </p>
                    
                    <div className="w-full Sign_Up_body_bkg">
                        <div className="flex flex-row flex_spacing mb-2">
                            <p className="font-Baskerville text-primary_light text-2xl">Sign In</p>
                            <a href="#" onClick={()=>{navigate(-1)}}>
                                <div className="flex flex-row gap-3 items-center">
                                    <ion-icon name="arrow-back-outline"></ion-icon>
                                    <p className="text-gray-500 font-Montserrat">Back</p>
                                </div>
                            </a>
                        </div>

                        {(error)? <Alert variant="filled" severity="error">{error}.</Alert> : ""}

                        <div name="Email_Password_Input" className="flex flex-col query_gaps mt-[20px]">
                            <input ref={emailRef} type="email" name="User_Email" id="User_Email" placeholder="Email" className="query_inputs" />
                            <input ref={passwordRef} type="password" name="User_Password" id="User_Password" placeholder="Password" className="query_inputs" />
                        </div>
                        <div name="buttons" className="flex flex-col sm:flex sm:flex-row query_gaps mt-[20px]">
                            <button onClick={()=>{ if(loading){return; } attempLogin() }} className="flex items-center justify-center text-white rounded-[10px] font-Montserrat text-[10px] font-bold sm:w-1/4 h-[30px] sm:h-auto button_styles">
                                {(loading)? <Icons.FaCircleNotch className="fa-spin text-[13pt]"/> :  "Sign In"}
                            </button>
                            <div className="flex flex-row query_gaps_smaller font-Montserrat text-[10px]">
                                <p>Don't have an account?</p>
                                <a className="text-primary_light font-extrabold"><Link to="/signup">Sign Up</Link></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Signin;