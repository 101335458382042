import { Helmet } from "react-helmet";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios"
import toast from "react-hot-toast"
import * as Icons from "react-icons/fa"
import Alert from "@mui/material/Alert"
import { useState, useRef } from "react"

const host = process.env.REACT_APP_API_HOST

const Signup = () => {

    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const [passwordMismatch, setPasswordMismatch] = useState(false)
    let navigate = useNavigate()

    const fullnameRef = useRef()
    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmationRef = useRef()
    
    const registerUser = ()=>{
        if(passwordMismatch){
            return
        }

        setLoading(true)
        axios.post(host + "/api/auth/signup", {
            "fullname": fullnameRef.current.value,
            "email": emailRef.current.value,
            "password": passwordRef.current.value
        }, {
            headers: {
                Accept: "application/json"
            }
        }).then((res)=>{
            setErrors([])
            if(!res.data.status){
                setErrors(res.data.error_description.errors)
                return toast.error(res.data.error_message)
            }
            if(res.status === 500){
                return toast.error("Unable to process request, please try again later")
            }
            return toast.success("Registration Successful", {position: "top-center"})
        }).catch((error)=>{
            toast.error(error.message, {hideProgressBar: true})
        }).finally(()=>{
            setLoading(false)
        })
    }

    const onValueChange = (event)=>{
        setPasswordMismatch(passwordRef.current.value !== passwordConfirmationRef.current.value)
    }

    return (
        <>
        <Helmet>
            <title>Sign Up - Kimpact</title>
        </Helmet>
        <div className="Sign_Up_body">
                <div name="Sign_Up_Page_Content" className="Sign_Up_Page_Body ">
                    <p className="w-full font-Baskerville text-high_ctrst md:text-left dual_flex_width text-center text-[18pt] leading-[35px] max-sm:text-[13pt]">
                        Sign up to access the
                        Election Petition Tribunal Citizen Dashboard (EPT Citizen Dashboard)
                    </p>
                    <div className="w-full Sign_Up_body_bkg">
                        <div name="Sign_In_Header" className="flex flex-row flex_spacing">
                            <p className="font-Baskerville text-primary_light text-2xl mb-[20px]">Sign Up</p>
                            <a href="#" onClick={()=>{navigate(-1)}}>
                                <div className="flex flex-row gap-3 items-center">
                                    <ion-icon name="arrow-back-outline"></ion-icon>
                                    <p className="text-gray-500 font-Montserrat">Back</p>
                                </div>
                            </a>
                        </div>

                        {errors.map((error)=><div className="mb-2"><Alert variant="filled" severity="error">{error}</Alert></div>)}

                        <div name="Email_Password_Input" className="flex flex-col query_gaps_smaller">
                            <input ref={fullnameRef} type="text" name="User_First_Name" id="User_First_Name" placeholder="First Name" className="query_inputs" />
                            <input ref={emailRef} type="email" name="User_Email" id="User_Email" placeholder="Email" className="query_inputs" />
                            <input ref={passwordRef} onChange={onValueChange} type="password" name="User_Password" id="User_Password" placeholder="Choose Password" className="query_inputs" />
                            {(passwordMismatch)? <div className="w-full flex justify-start"><Alert severity="error">Password Mismatch</Alert></div> : ""}
                            <input ref={passwordConfirmationRef} onChange={onValueChange} type="password" name="User_Password_Confirm" id="User_Password_Confirm" placeholder="Confirm Password" className="query_inputs" />
                        </div>
                        
                        <div name="buttons" className="flex flex-col sm:flex sm:flex-row query_gaps mt-[20px]">
                            <button onClick={()=>{ if(loading){return; } registerUser() }} className="flex items-center justify-center text-white rounded-[10px] font-Montserrat text-[10px] font-bold sm:w-1/4 h-[30px] sm:h-auto button_styles">
                                {(loading)? <Icons.FaCircleNotch className="fa-spin text-[13pt]"/> :  "Sign Up"}
                            </button>
                            <div className="flex flex-row query_gaps_smaller font-Montserrat text-[10px]">
                                <p>Already have an account?</p>
                                <a className="text-primary_light font-extrabold"><Link to="/signin">Sign In</Link></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </>
    );
}

export default Signup;