import { Helmet } from "react-helmet";
import Paginator from "../components/Paginator"
import { FaDownload } from "react-icons/fa"
import { useState } from "react"
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query"
import axios from "axios"
import ClipLoader from "react-spinners/ClipLoader";
import toast, { useToasterStore } from "react-hot-toast";
import fileDownload from "js-file-download"
import mime from "mime"
import { useSelector } from "react-redux"

const queryClient = new QueryClient()
const HOST = process.env.REACT_APP_API_HOST

export default (props)=>{
    return <QueryClientProvider client={queryClient}>
        <ResourcesPage />
    </QueryClientProvider>
}

const ResourcesPage = (props)=>{
    const [currentPage, setCurrentPage] = useState(1)
    const token = useSelector((state)=>state.authToken.token)

    const url = HOST + `/api/resources/${currentPage}`
    const { isLoading, error, data} = useQuery({
        queryKey: [url],
        queryFn: ()=>axios.get(url).then((res)=>{ return res.data.data }),
        retry: 3
    })

    const showContent = ()=>{
        if(isLoading){
            return <div style={{height: "30vh"}} className="flex justify-center items-center"><ClipLoader color="#034179" /></div>
        }else if(error){
            return <p class="text-center mt-[20px] font-Montserrat text-primary">Unable to retrieve data, try again in a moment</p>
        }

        return (
            <>
                <div className="min-[850px]:grid grid-cols-3 gap-[30px] auto-rows-[minmax(0,_3fr)] mt-[30px]">
                    { data.resources.map((item)=><ResourceItem key={item.id} title={item.name} id={item.id} downloadLinkHandler={downloadLinkHandler}/>) }
                </div>
                <div className="flex justify-center mt-[50px]">
                    <Paginator onPageChange={(event)=>{ setCurrentPage(event.selected + 1);}} pageCount={data.totalPages} currentPage={currentPage - 1}/>
                </div>
            </>
        )
    }

    const downloadLinkHandler = (url)=>{
        axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res)=>{
            if(res.status == 500){
                toast.error("Sorry, we cannot process your request at the moment, please try again later")
                return
            }
            if(res.status == 401){
                toast.error("Access Denied, login required to download file")
                return
            }
            if(!res.data.status){
                toast.error(res.data.error_message)
                return
            }
            const authToken = res.data.data.token
            const downloadLink = url + `/authorization?authorization=${authToken}`
            downloadFile(downloadLink)
        }).catch((error)=>{
            try{
                if(error.response.status == 401){
                    toast.error("Access Denied, login required to download file")
                    return
                }
            }catch(err){
                // just ignore the error
            }            
            toast.error(error.message)
        })
        toast("Download will start in a moment...", {position: "bottom-left", duration: 2000})
    }

    const downloadFile = (url)=>{
        // axios.get(url).then((res)=>{
        //     if(res.status == 500){
        //         toast.error("Sorry, we cannot process your request at the moment, please try again later")
        //         return
        //     }
        //     if(res.headers["content-type"] == "application/json"){
        //         if(!res.data.status){
        //             toast.error(res.data.data.message)
        //             return
        //         }
        //         toast.error("Sorry, your file could not be downloaded")
        //         return
        //     }

            
        // }).catch((error)=>{
        //     toast.error(error.message)
        // })

        // create anchor element to trigger the download
        const anchor = document.createElement("a")
        anchor.href = url
        
        // invoke the download
        anchor.click()

        URL.revokeObjectURL(url)
    }

    return (
        <>
            <Helmet>
                <title>Resources - Kimpact</title>
            </Helmet>
            <div class="flex flex-col items-center justify-center px-[8%] min-[850px]:py-[50px]">
                
                <p className="font-Baskerville max-[850px]:text-[19pt] text-[24pt] text-center text-primary font-medium mt-[50px]">Resources</p>

                <hr className="h-[2px] bg-[#0A2458] w-[55%] my-[40px]" />
                
                { showContent() }

            </div>
        </>
    )
}

const ResourceItem = (props)=>{
    
    return (
        <div className="bg-[#303030] rounded-[25px] p-[40px] mb-[15px] min-[850px]:max-w-[400px] text-white bg-center bg-contain bg-blend-overlay bg-no-repeat flex min-[850px]:flex-col gap-[20px] justify-between max-[850px]:items-between" style={{ backgroundSize: "160px", backgroundImage: "url('/assets/img/pdf-icon.webp')"}} >
            <div className="h-full flex items-center">
                <p className="font-Baskerville max-[850px]:text-[11pt] max-lg:text-[12pt] text-[14pt] min-[850px]:text-center">{props.title}</p>
            </div>
            <a onClick={()=>{props.downloadLinkHandler(HOST + `/api/resources/download/${props.id}`, props.title)}} className="font-Montserrat cursor-pointer flex justify-center items-center gap-[15px] max-lg:text-[10pt] text-[11pt]">Download <FaDownload /></a>
        </div>
    )
}
