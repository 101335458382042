import { Helmet } from "react-helmet";

const About = () => {
    return (
        <>
            <Helmet>
                <title>About - EPT Dashboard</title>
            </Helmet>
            <div className="flex flex-col items-center justify-center md:px-[20%] px-[50px] mt-5 gap-[40px]">
                <div className="flex flex-col justify-start gap-[40px]">

                    <p className="font-Baskerville text-primary font-bold text-4xl max-xl:mt-[50px] max-md:text-[18pt] max-md:leading-[45px] xl:mt-[100px] leading-[55px]">
                        About the Election Petition Citizen Dashboard (EPT Citizen Dashboard)
                    </p>

                    <hr className="h-[2px] bg-[#0A2458] w-[70%]" />

                    <p className="font-Baskerville text-[15px] leading-[35px] text-primary_light">
                        The EPT Citizen Dashboard is a one-stop platform where information on the
                        Electoral Justice System, especially the EPT, can be accessed by the citizen
                        is very important to citizen participation and public enlightening of the EPT
                        processes and procedures. This is also one of the recommendations of the public
                        perception survey (Gauging Public Perception and Citizens’ Confidence
                        Level in the Election Petition Tribunals in Nigeria) conducted by KDI.
                        <br />
                        Given this, the dashboard provides an at-a-glance overview but not an abridged version of the information gathered from the EPT nationwide. This dashboard displays the status of information should as:
                        The total number of petitions filled in the country after every general election since 2003.
                        Disaggregation of petitions to state and geo-political zone.
                        The overall percentage of each ground of the petition in which the petitioner approached the court.

                        The petition log, where the user can see the details and get a copy of all petitions filed.

                        The judgement log, where the user can see the details and get a copy of all judgement delivered.

                        Analytics session where users can do some comparative analysis of information based on an election year and election types.

                    </p>
                    <div className="flex flex-col gap-[10px] sm:gap-[5px]">
                        <p className="font-Montserrat text-[11px] font-bold text-gray-500">This initiative is supported by</p>
                        <div className="sm:w-3/4">
                            <img className="sm:h-auto w-full object-contain" src="/assets/img/Partner logos.png" alt="Partner logos" />
                        </div>
                        <p className="font-Baskerville text-[11px] text-primary_light leading-[25px] mt-5 sm:w-3/4">International Foundation for Electoral Systems (IFES),
                            the United States Agency for International Development (USAID) and the Foreign Commonwealth and
                            Development Office (FCDO) under the Election Petition Tribunal Monitoring (EPTM) Project.</p>
                    </div>
                    <p className="quote_inliner">The 2019 observations and recommendations made were instrumental in reviewing the 2022 Judicial Electoral Manual, as indicated on page 3 of the manual.</p>
                </div>
                <div className="flex flex-col justify-start gap-[20px] mt-[20px]">
                    <p className="font-Baskerville text-primary font-bold text-4xl sm:w-4/5 max-md:text-[18pt] max-md:leading-[45px]">
                        About KDI
                    </p>
                    
                    <hr className="h-[2px] bg-[#0A2458] w-[70%]" />
                    
                    <p className="font-Baskerville text-[15px] text-primary_light mt-[20px] leading-[35px]">
                        Kimpact Development Initiative (KDI) is an independent non-governmental organization that advances good governance,
                        democratic rights, public policy, and public engagement. We do this by building informed and active citizens through capacity development, advancing public policies, data-driven advocacy and reforms that give a more supportive environment for citizen-led development.
                    </p>
                </div>
                <div className="flex flex-col justify-start gap-[20px]">
                    <p className="font-Baskerville text-primary font-bold text-4xl sm:w-4/5 max-md:text-[18pt] max-md:leading-[45px]">
                        Our Mission
                    </p>

                    <hr className="h-[2px] bg-[#0A2458] w-[70%]" />

                    <p className="font-Baskerville text-[15px] text-primary_light mt-[20px] leading-[35px]">
                        To inspire citizen-led democratic and economic development that is fixed firmly on the principles of participation, data-driven advocacy, strong democratic institutions, and public policies.
                    </p>
                </div>
            </div>
        </>
    )
}

export default About;