import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom"
import MasterLayout from "./components/layouts/MasterLayout"
import Home from "./pages/Home"
import About from "./pages/About"
import Resources from "./pages/Resources"
import Signup from "./pages/Signup"
import Signin from "./pages/Signin"
import Blog from "./pages/Blog"
import BlogStory from "./pages/BlogStory"
import "./styles/App.css"
import "./styles/style.css"
import "react-toastify/dist/ReactToastify.css"
import WorldData from "./pages/WorldData"

const App = ()=>{
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<MasterLayout/>}>
                    <Route index element={<Home/>} />
                    <Route path="/about" element={<About/>}/>
                    <Route path="/resources" element={<Resources/>}/>
                    <Route path="/signup" element={<Signup/>}/>
                    <Route path="/signin" element={<Signin/>}/>
                    <Route path="/blog" element={<Blog/>}/>
                    <Route path="/blog/story/:storyId" element={<BlogStory/>}/>
                    <Route path="/*" element={()=>{
                        return (
                            <>
                            <p>404 Page Not Found</p>
                            </>
                        );
                    }}/>
                </Route>
                <Route path="/world-data" element={<WorldData/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;