import Footer from "../components/Footer"
import { Link, useNavigate } from "react-router-dom"
import { Box, Button } from "@mui/material"
import { SendOutlined, SearchOutlined, CloseOutlined } from "@ant-design/icons"
import { FaLessThanEqual, FaSearch, FaTimes } from "react-icons/fa"
import "../styles/WorldData.css"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import Fab from "@mui/material/Fab"
import NavigationIcon from '@mui/icons-material/Navigation'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useEffect, useState, useRef, Fragment } from "react"
import { UisBars, UisTimesCircle } from '@iconscout/react-unicons-solid'
import africanCountryData from "../assets/country-data.json"
import countryList from "../assets/countries_list.json"
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import ClipLoader from "react-spinners/ClipLoader"
import ScaleLoader from "react-spinners/ScaleLoader"
import axios from "axios"
import { KeySharp } from "@mui/icons-material"
import { faHourglassEmpty, faListNumeric } from "@fortawesome/free-solid-svg-icons"
import { Toaster } from "react-hot-toast"
import toast from "react-hot-toast"

const HOST = process.env.REACT_APP_API_HOST
const ANSWER_API = HOST + "/api/justice_database/answer"
const SUGGESTIONS_API = HOST + "/api/justice_database/questions/suggestions"

export default () => {

  const [faqHeight, setFaqHeight] = useState(500)
  const leftContainerRef = useRef()
  const [showFAQ, setShowFAQ] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const searchBarRef = useRef(null)
  const [selectedCountry, updateSelectedCountry] = useState(null)
  const isShowFaqs = ()=> showFAQ && windowWidth <= 1024
  const [searchError, setError] = useState(false)

  const [questionData, setQuestionData] = useState({
    isLoading: false,
    error: false,
    data: null
  })

  const [suggestionsData, setSuggestionsData] = useState({
    isLoading: false,
    error: false,
    questions: null
  })

  const updateWindowWidth = ()=>{
    setWindowWidth(window.innerWidth)
  }

  useEffect(()=>{
    document.body.style.overflowY = "hidden"
    window.addEventListener("resize", updateWindowWidth)

    getSuggestions()

    return ()=>{
      document.body.style.overflowY = "auto"
      window.removeEventListener("resize", updateWindowWidth)
    }
  }, [])

  const faqsMobileStyles = {
    overflowY:"auto", 
    display: "block", 
    zIndex: "5", 
    width: "100%", 
    height: "100vh", 
    position: "fixed", 
    left: "0px", 
    top: "0px", 
    background: "white",
  }

  const askQuestion = ()=>{
    if(questionData.isLoading == true){
      return
    }
    
    let question = searchBarRef.current.value
    if(question.length == 0){
      return
    }

    if(selectedCountry == null){
      setError(true)
      return
    }else if(searchError){
      setError(false)
    }
    
    axios.post(ANSWER_API, {
      "question": question,
      "country": selectedCountry
    },{
      "headers": {
        "Content-Type": "application/json"
      }
    }).then((data)=>{
      setQuestionData({
        isLoading: false,
        error: !data.data.status,
        data: data.data
      })
      // there are no suggestions in this request since it failed
      if(data.data.status){
        setSuggestionsData({
          isLoading: false,
          error: false,
          data: data.data
        })
      }
    }).catch((error)=>{
      setQuestionData({
        isLoading: false,
        error: null,
        data: null
      })
    })
    setQuestionData({
      isLoading: true,
      error: false,
      data: null
    })
  }

  const getSuggestions = () =>{
    if(suggestionsData.isLoading == true){
      return
    }
 
    axios.get(SUGGESTIONS_API, {
      "headers": {
        "Content-Type": "application/json"
      }
    }).then((data)=>{
      setSuggestionsData({
        isLoading: false,
        error: !data.data.status,
        data: data.data
      })
      console.log(data.data.status)
    }).catch((error)=>{
      setSuggestionsData({
        isLoading: false,
        error: null,
        data: null
      })
    })
    setSuggestionsData({
      isLoading: true,
      error: false,
      data: null
    })
  }

  return (
    <>
      <Toaster 
          position="top-right" 
          reverseOrder={true}
          toastOptions={{
              className: '',
              style: {
                fontSize: '10pt',
                fontWeight: "500",
                fontFamily: 'Montserrat'
              },
          }}
      />

    {/* button to hide and reveal FAQs section in mobile view */}
		<Box sx={{right: (isShowFaqs())? "-500px":"25px"}} className="transition-all lg:hidden fixed z-[10] bottom-[50px]">
			<Fab id="FabButton" onClick={()=>{setShowFAQ(true)}} sx={{textTransform: "capitalize", background: "#034179", padding: "10px"}} variant="extended">
				<img src={process.env.PUBLIC_URL + "/assets/icons/globe.svg"}/>
				<p className="font-Montserrat ml-[10px] text-white">Countries</p>
			</Fab>
		</Box>

      <NavBar />

      <div className="overflow-y-auto h-[89vh]">
        <div className="WorldData flex relative bg-[#ebf5ff22]">
          <div className="fixed h-full z-[-1] flex items-end">
            <img
              className="w-full"
              src={process.env.PUBLIC_URL + "/assets/img/world_map.svg"}
            />
          </div>

          <div className="flex w-full gap-[10%] p-[30px_5%] lg:p-[50px_10%]">
            {/* left section */}
            <div ref={leftContainerRef} className="w-[65%] max-lg:w-full">
              <div className="text-[10pt] text-primary">
                <p className="font-bold text-[12pt] lg:text-[15pt]">
                  Welcome to the Africa Electoral Justice Data Hub!
                </p>
                <p className="mt-[20px] font-Montserrat">
                  The Africa Electoral Justice Data Hub offers comprehensive data on Election Dispute Resolution (EDR) mechanisms 
                  across 54 African countries. Tailored to meet the needs of researchers, election practitioners, legal experts, 
                  civil society organizations, and citizens, it provides insights into the design of EDR mechanisms in Africa and 
                  how specific practices can influence outcomes. 
                </p>
                <p className="font-Montserrat mt-[10px] mb-[30px]">
                  Note: The data is sourced from the International IDEA Electoral Justice Database, ensuring regular updates as 
                  country-specific information becomes available.
                </p>
              </div>

              {/* Sarch bar */}
              <>
                <div style={{background: "linear-gradient(90deg, #2D9BFF, #65B14D, #2172BB, #1B5D99)"}} className="rounded-full p-[5px] shadow-md">
                <div className="bg-white rounded-full cursor-pointer p-[30px] w-full h-[10px] text-[10pt] flex items-center font-Montserrat">
                  <input
                    ref={searchBarRef}
                    onKeyDown={(e)=>{
                      if(e.key === "Enter"){
                        askQuestion()
                      }
                    }}
                    type="text"
                    className="w-full font-medium bg-transparent"
                    placeholder="Search Data on World Electoral Justice"
                  />
                  <Button onClick={askQuestion} sx={{borderRadius: "50px"}}>
                  <img
                    className="h-[20px]"
                    src={process.env.PUBLIC_URL + "/assets/icons/send_filled.svg"}
                  />
                  </Button>
                </div>
                </div>
                
                { (searchError && selectedCountry == null) && <p className="text-[red] font-Montserrat text-[10pt] mt-[20px] ml-[30px]">Please select a country to search</p> }
                { (selectedCountry) && <p className="text-primary font-Montserrat text-[10pt] mt-[20px] ml-[30px]">Selected Country: {selectedCountry}</p> }
              </>

              {
                (questionData.isLoading == false && questionData.data == null && questionData.error == false)? "" : 
                <div className="shadow-md p-[30px] font-Montserrat relative bg-[#F3F9FFCC] z-[2] w-full mt-[30px] rounded-[20px] border-[1px] border-[#034179]">
                  { (questionData.isLoading)? <div className="text-center"><ScaleLoader height="10px" color="#034179"/></div> : 
                    <Fragment>
                      { (questionData.error || !questionData.data)? <p className="text-primary text-center text-[10pt]">An error occurred, please try again</p> : 
                        <div className="custom-scrollbar overflow-y-auto pr-[5px]">
                          <p className="font-semibold text-secondary_teal">
                            {questionData.data.data.question}
                          </p>

                          <div className="text-[10pt] text-primary">
                            <p className="mt-[30px]">
                              {questionData.data.data.response}
                            </p>
                          </div>
                        </div>
                      }
                    </Fragment>
                  }

                </div>
              }

              <div className="shadow-md p-[30px] relative bg-[#F3F9FFCC] z-[2] w-full mt-[30px] rounded-[20px] border-[1px] border-[#034179]">
                <div className="custom-scrollbar overflow-y-auto pr-[5px]">
                  <div className="flex items-center">
                    <p className="w-full font-semibold text-[11pt] lg:text-[14pt] text-secondary_teal">
                      Suggestions
                    </p>
                    <div>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/icons/light_bulb.svg"
                        }
                      />
                    </div>
                  </div>
                  <hr className="h-[1.5px] mt-[20px] bg-primary" />
                  {
                    (suggestionsData.isLoading)? <div className="mt-[20px] text-center"><ClipLoader color="#034179" ></ClipLoader></div> : 
                    <>
                      {
                        (suggestionsData.error || !suggestionsData.data)?
                        <p className="font-Montserrat text-center mt-[20px] text-[10pt] leading-[18px] font-normal text-primary rounded-[10px] w-full">
                        Unable to load suggestions at the moment
                        </p> :
                        <Fragment>
                          <div style={{gridTemplateColumns: "auto auto"}} className="lg:grid text-[10pt] gap-[10px] mt-[20px] font-Montserrat text-primary">
                            {
                              suggestionsData.data.data.otherSuggestions.map((data)=>{
                                return <Button onClick={()=>{
                                  searchBarRef.current.value = data
                                  askQuestion()
                                  }} sx={{textTransform: "capitalize", textAlign: "left"}}>
                                  <p className="font-Montserrat text-[10pt] leading-[18px] font-normal text-primary transition-all cursor-pointer hover:text-secondary_teal rounded-[10px] w-full">
                                    {data}
                                  </p>
                                </Button>
                              })
                            }
                          </div>
                        </Fragment> 
                      }
                    </>
                  }
                </div>
              </div>
            </div>

            {/* right section */}
            <div style={(isShowFaqs())? faqsMobileStyles : {}} className={["w-[35%] max-lg:hidden"].join(" ")}>
              
              <div className="lg:hidden mb-[30px] flex justify-end">
                <Button onClick={()=>{setShowFAQ(false)}} sx={{padding:"20px", borderRadius:"50px"}}>
                  <FaTimes className="text-black text-[13pt]"/>
                </Button>
              </div>

              {/* country information */}
                <CountryFaqs showFaqs={isShowFaqs()} onUpdateCountry={updateSelectedCountry}/>
            </div>
          </div>
        </div>

        <div className="bg-white">
			<Footer />
		</div>
      </div>
    </>
  )
}

const CountryFaqs = (props) => {

  const [open, setOpen] = useState(false);
  const [countryData, setCountryData] = useState(null)
  const [countriesFilter, setCountriesFilter] = useState('')
  const countriesFilterRef = useRef(null)
  const [expandedKey, setExpandedKey] = useState(null)

  const handleClickOpen = (data) => {
    setCountryData(data)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setCountryData(null)
  }

  const generalInformationKeys = [
    "country",
    "region",
    "official_name",
    "capital",
    "area",
    "population",
    "official_languages",
    "capita_income",
    "form_of_government",
  ]

  const otherInformationKeys = [    
    "administrative_division",
    "executive_power",
    "legislative_power",
    "judiciary",
    "electoral_jurisdiction",
    "majority_parties",
    "current_political_situation",
    "vote"
  ]

  // return a list of filtered countries from the user input
  const filterCountries = (list, input)=>{
    const regex = new RegExp(`.?${input}.?`, "i")
    return list.filter(item=> regex.test(item.country)).sort((a,b)=>a["country"].localeCompare(b["country"]))
  }

  return (
    <Fragment>

      <Fragment>
        <Dialog
          fullWidth={"80%"}
          open={open}
          onClose={handleClose}
        >

          <DialogTitle><p className="font-bold text-primary text-center font-Montserrat">{(countryData)? countryData.country : "Loading..." }</p></DialogTitle>
          <DialogContent>
            <DialogContentText>
              
            </DialogContentText>
            <Box
              sx={{
                width: 'fit-content',
              }}
            >
              
              
              {
                // general information
                (!countryData)? <ClipLoader color="#034179" /> :  
                  (generalInformationKeys).map((title)=>{
                    const infoTitle = title.split("_").map(d=>d[0].toUpperCase() + d.substring(1)).join(" ")
                    let content = countryData[title]
                    if(title == "official_languages"){
                      content = <ul className="pl-[25px] list-disc">
                        {
                          countryData[title].map((t)=>{
                            return <li>{t}</li>
                          })
                        }
                      </ul>
                    }

                    return <Typography
                      sx={{
                        marginTop: "5px",
                        fontFamily: "Montserrat",
                        fontSize: "10pt",
                        color: "#034179",
                      }}
                    >
                    <span className="font-bold">{infoTitle}:</span> <span className="">{content}</span>
                  </Typography>
                  })
              }

              {
                (!countryData)? "" :
                <div className="mt-[20px]">
                  {
                    (otherInformationKeys).map((title)=>{
                    const infoTitle = title.split("_").map(d=>d[0].toUpperCase() + d.substring(1)).join(" ")
                    console.log(title)
                    let content = countryData[title].map((d)=><p className="pt-[5px]">{d}</p>)
                    if(title == "majority_parties"){
                      content = <ul className="pl-[25px] list-disc">
                        {
                          countryData[title].map((t)=>{
                            return <li>{t}</li>
                          })
                        }
                      </ul>
                    }
                    return <>
                        <Typography
                          sx={{
                            marginTop: "10px",
                            fontFamily: "Montserrat",
                            fontSize: "10pt",
                            color: "#139DB8",
                            fontWeight: "700",
                          }}
                        >
                          {infoTitle}
                      </Typography>

                      <Typography
                          sx={{
                            marginTop: "5px",
                            fontFamily: "Montserrat",
                            fontSize: "9pt",
                            color: "#034179",
                          }}
                          component="div"
                          children={content}
                        >
                      </Typography>
                    </>
                  })
                }
                </div>
              }
            </Box>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}><span className="font-Montserrat">Close</span></Button>
          </DialogActions>
        </Dialog>
      </Fragment>

      <div className="p-[0px_5%]">
        <div className="p-[10px_25px] pt-0 flex w-full border-b-[1.5px] border-primary font-Montserrat">
          <input
            ref={countriesFilterRef}
            onChange={(e)=>{setCountriesFilter(e.target.value)}}
            className="w-full text-[10pt] bg-transparent placeholder:text-primary text-primary font-semibold"
            type="text"
            placeholder={(expandedKey == null)? "Search Country name" : "Selected: " + expandedKey}
          />
          <SearchOutlined className="text-[18pt] text-primary" />
        </div>
        
        <div style={(props.showFaqs)? {}:{height: "500px"}} className="border-primary custom-scrollbar overflow-y-auto mt-[20px] pr-[15px]">
          {filterCountries(africanCountryData, countriesFilter).map((data, key) => {
            return (
              <>
                <Accordion
                  key={data.country}
                  disableGutters={true}
                  expanded={(expandedKey == data.country)? true : false}
                  onChange={(e, isExpanded)=>{
                    if(isExpanded) {
                      setExpandedKey(data.country)
                      props.onUpdateCountry(data.country)
                    }else{
                      setExpandedKey(null)
                      props.onUpdateCountry(null)
                    }
                  }}
                  sx={{
                    boxShadow: "none",
                    background: "transparent",
                    color: "white",
                    margin: "5px 0px"
                  }}
                >
                  <AccordionSummary
                    sx={{
                      backgroundColor: "#034179",
                      borderRadius: "10px",
                      height: "50px",
                      padding: "15px",
                      minHeight: "auto",
                    }}
                    expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography sx={{ fontSize: "10pt", fontFamily: "Montserrat" }}>
                      {data.country}
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails
                    sx={{ background: "transparent", padding: "25px 25px", paddingRight: "0px" }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontSize: "10pt",
                        color: "#139DB8",
                        fontWeight: "700",
                      }}
                    >
                      General Information:
                    </Typography>
                                    
                    {
                      (generalInformationKeys).map((title)=>{
                        const infoTitle = title.split("_").map(d=>d[0].toUpperCase() + d.substring(1)).join(" ")
                        let content = data[title]
                        if(title == "official_languages"){
                          content = <ul className="pl-[25px] list-disc">
                            {
                              data[title].map((t)=>{
                                return <li>{t}</li>
                              })
                            }
                          </ul>
                        }

                        return <Typography
                          sx={{
                            marginTop: "5px",
                            fontFamily: "Montserrat",
                            fontSize: "10pt",
                            color: "#034179",
                          }}
                        >
                        <span className="font-bold">{infoTitle}:</span> <span className="text-[9pt]">{content}</span>
                      </Typography>
                      })
                    }
                    
                    <div className="flex justify-center">
                      <Button sx={{marginTop: "20px", textTransform: 'capitalize'}} onClick={()=>{handleClickOpen(data)}}>
                        <Typography
                          sx={{
                            fontFamily: "Montserrat",
                            fontSize: "10pt",
                            fontWeight: "700",
                            textAlign: "center",
                          }}
                        >
                          <span className="cursor-pointer text-primary">View More</span>
                        </Typography>
                      </Button>
                    </div>

                  </AccordionDetails>
                </Accordion>
              </>
            );
          })}
        </div>
      </div>
    </Fragment>
  )
}

const pages = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "About",
    link: "/about",
  },
  {
    title: "Blog",
    link: "/blog",
  },
  {
    title: "Resources",
    link: "/resources",
  },
]

const unauthPages = [];

const SearchBar = () => {
  return (
    <>
      <div style={{background: "linear-gradient(90deg, #2D9BFF, #65B14D, #2172BB, #1B5D99)"}} className="rounded-full p-[5px] shadow-md">
		<div className="bg-white rounded-full cursor-pointer p-[30px] w-full h-[10px] text-[10pt] flex items-center font-Montserrat">
			<input
				type="text"
				className="w-full font-medium bg-transparent"
				placeholder="Search Data on World Electoral Justice"
			/>
			<Button onClick={askQuestion} sx={{borderRadius: "50px"}}>
			<img
				className="h-[20px]"
				src={process.env.PUBLIC_URL + "/assets/icons/send_filled.svg"}
			/>
			</Button>
		</div>
	  </div>
    </>
  );
};

const NavBar = () => {
  const navigate = useNavigate()
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)

  return (
    <>
      <div
        className="h-[75px] relative z-[4] gap-[30px] bg-[#1976d2] text-white flex items-center p-[0px_5%] lg:p-[0px_10%]"
        style={{ borderBottom: "1vh solid #80E061" }}
      >
        <div className="">
          {/* logo container */}
          <img
            key={"mobile_logo"}
            style={{ minWidth: "80px", width: "100px" }}
            src="/assets/img/KDI_White_logo.png"
          />
        </div>

        <div className="w-1/2 max-lg:w-full">
          {/* title container */}
          <p className="lg:text-[18px] text-[15px] font-semibold">Electoral Justice Data</p>
        </div>

		{/* links section */}
        <div className="w-1/2  flex justify-end max-lg:hidden">
          {/* links container */}
          {pages.concat(unauthPages).map((page) => {
            return (
              <>
                <Button
				  key={page.link}
                  sx={{
                    textTransform: "capitalize",
                    my: 2,
                    mx: 2,
                    color: "white",
                    display: "block",
                    fontFamily: "Montserrat",
                  }}
                >
                  <div
                    className="flex gap-[5px] items-center"
                    onClick={() => {
                      navigate(page.link);
                    }}
                  >
                    <Link className="text-[9pt]">{page.title}</Link>
                  </div>
                </Button>
              </>
            )
          })}
        </div>

		{/* harmburger to display on smaller screens */}
		<Box className="lg:hidden">
			{ 
				(isMobileNavOpen)? <CloseOutlined onClick={() => { setIsMobileNavOpen(false) }} className="text-[14pt] cursor-pointer"/> : 
				<UisBars onClick={() => { setIsMobileNavOpen(true) }} className="cursor-pointer"/>
			}
		</Box>
      </div>
	  
		{/* mobile navigation */}
		<div style={{top: (isMobileNavOpen)? "75px":"-500px"}} className="transition-all duration-[500ms] bg-primary lg:hidden absolute left-0 w-full z-[3] flex flex-col items-center shadow-md">
			{pages.concat(unauthPages).map((page) => {
				return (
				<>
					<Button
					key={page.link}
					sx={{
						textTransform: "capitalize",
						my: 2,
						mx: 2,
						color: "white",
						display: "block",
						fontFamily: "Montserrat",
					}}
					>
					<div
						className="items-center"
						onClick={() => {
						navigate(page.link);
						}}
					>
						<Link className="text-[9pt]">{page.title}</Link>
					</div>
					</Button>
				</>
				)
			})}
		</div>
    </>
  )
}