import { getDefaultNormalizer } from "@testing-library/react"
import "../../styles/Dropdown.css"
import React, {Component, useEffect, useRef, useState} from "react"

class Dropdown extends Component{

    leftOffset = 0

    constructor(props){
        super(props)
        
        this.dropDownRef = React.createRef()
        this.popupRef = React.createRef()
    
        this.state = {
            isOpenState: false,
            selectedIndex: 0,
        }
    }

    items = this.props.menuItems.map((item, index)=>{
        return (
            <div key={Math.random() + item}>  {(index > 0 || index < this.props.menuItems)? <hr className="text-[#0D5DA6]"/> : ""}
                <a className="
                    cursor-pointer
                    filter-item
                    block 
                    font-normal
                    p-[15px] 
                    hover:bg-secondary_teal"
                    onMouseDown={(e)=>{e.preventDefault()}}
                    onClick={()=>{
                        this.setState({selectedIndex: index})
                        this.props.onChange(this.props.menuItems[index])
                    }}>{item}</a>
            </div>
        );
    });


    render(){        
        const left = `${this.leftOffset * -1}px`
        const dropdown = (
            <div ref={this.popupRef} className={["z-[2000]", "menu-dropdown", "absolute", "top-[50px]", "bg-primary", "w-[200px]", "rounded-[20px]"].join(" ")} style={{left: left, maxHeight: "300px", overflowY: "auto"}}>
                {this.items}
            </div>
        )

        return <>
            <div ref={this.dropDownRef} className="relative">
                <a tabIndex="0" className="flex items-center cursor-pointer filter-item menu" 
                onClick={(e)=>{
                    const rect = this.dropDownRef.current.getBoundingClientRect()
                    const xPosition = rect.left + window.scrollX
                    const documentWidth = document.documentElement.clientWidth
                    const POP_WIDTH = 200
                    
                    if(xPosition + POP_WIDTH >= documentWidth){
                        this.leftOffset = Math.floor((xPosition + POP_WIDTH - documentWidth) + 20)
                    }

                    this.setState({isOpenState: !this.state.isOpenState})
                }}
                onBlur={()=>{ this.setState({isOpenState: false})}}>
                    <>
                        <span className=" max-w-[100px] text-primary">{(this.state.selectedIndex == 0)? this.props.title : this.props.menuItems[this.state.selectedIndex]}</span>
                        <img src="/assets/icons/angle_down_block.svg" className="relative text-secondary_teal text-[14pt] ml-[10px]" />
                    </>
                </a>

                {this.state.isOpenState? dropdown : ""}
            </div>
        </>
    }
}

export default Dropdown;