import { Link } from "react-router-dom"
import { useState, useEffect } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import * as Unicons from '@iconscout/react-unicons'
import { UisBars, UisTimesCircle } from '@iconscout/react-unicons-solid'
import toast from "react-hot-toast"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { setAuthToken, clearAuthToken } from "./features/AuthTokenSlice"
import { FaSearch } from "react-icons/fa"
import SearchOverlay from "../components/SearchOverlay"
import { useNavigate } from "react-router-dom"

const pages = [
    {
        title: 'Home',
        link: '/'
    },
    {
        title: 'About',
        link: '/about'
    },
    {
        title: 'Blog',
        link: '/blog'
    },
    {
        title: 'Resources',
        link: '/resources'
    },
    {
        title: 'Data Hub',
        link: '/world-data'
    }
];

const unauthPages = [
    {
        title: 'Sign In',
        link: '/signin'
    },
];

const settings = ['Logout']
const HOST = process.env.REACT_APP_API_HOST

function ResponsiveAppBar() {
    const dispatch = useDispatch()
    const token = useSelector((state) => state.authToken.token)

    const [anchorElNav, setAnchorElNav] = useState(null)
    const [anchorElUser, setAnchorElUser] = useState(null)
    const [showExplore, setShowExplore] = useState(false)

    const navigate = useNavigate()

    const [showMenu, setShowMenu] = useState(false)

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setShowMenu(false)
    };

    const attemptLogout = () => {
        axios.post(HOST + "/api/auth/logout", {
            // nothing to send
        }, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then((data) => {
            toast.success("Logout successful!")
            dispatch(clearAuthToken())
        }).catch((err) => {
            toast.error(err.message)
        })
        toast("Logging you out...")
    }

    const handleCloseUserMenu = (setting) => {
        if (setting === "Logout") {
            attemptLogout()
        }
        setAnchorElUser(null);
    };

    useEffect(() => {
        if (showMenu || showExplore) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }
    }, [showMenu, showExplore])

    const AppDrawer = () => {
        return (

            <div className={["transition-all", "duration-500", "mt-[11vh]", "text-center", "fixed", "z-[100]", "bg-[#0D5DA6CC]", "px-[10px]", "h-[100vh]", "w-full", (showMenu) ? "" : "left-[-100%]"].join(" ")}>
                <div>
                    {((token) ? pages : pages.concat(unauthPages)).map((page) => (

                        <Button
                            key={`app_drawer_${page.title}`}
                            onClick={handleCloseNavMenu}
                            sx={{ width: '100%', my: 2, py: 2, textAlign: 'center', textTransform: 'capitalize', color: 'white', display: 'block', fontFamily: 'Montserrat' }}
                        ><Link to={page.link}>{page.title}</Link></Button>

                    ))}
                </div>
            </div>

        )
    }

    return (
        <>
            <AppDrawer />

            { (showExplore)? <SearchOverlay onCloseOverlay={()=>{setShowExplore(false)}}/> : "" }

            <AppBar position="static" elevation={0} sx={{}}>
                <Container maxWidth={false} sx={{ background: "#0D5DA6CC", borderBottom: '1vh solid #80E061' }}>
                    <Toolbar disableGutters sx={{ height: "10vh" }}>

                        <Box sx={{ marginLeft: '80px', display: { xs: 'none', md: 'flex' } }}>
                            <img key={"main_logo"} style={{ width: "100px" }} src="/assets/img/KDI_White_logo.png" />
                        </Box>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>

                            <UisBars onClick={() => { setShowMenu(!showMenu) }} className="cursor-pointer"/>

                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none', cursor: 'pointer'},
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={`main_menu_${page.title}`} onClick={handleCloseNavMenu}>
                                        <Link to={page.link}>{page.title}</Link>                                        
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>

                        <Box
                            sx={{
                                justifyContent: 'center',
                                width: '100%',
                                display: { xs: 'flex', md: 'none' },
                            }}
                        >
                            <img key={"mobile_logo"} style={{ width: "100px" }} src="/assets/img/KDI_White_logo.png" />
                        </Box>


                        <Box sx={{ flexGrow: 1, ml: 5, display: { xs: 'none', md: 'flex' } }}>
                            {((token) ? pages : pages.concat(unauthPages)).map((page) => (
                                <Button
                                    onClick={handleCloseNavMenu}
                                    sx={{ textTransform: 'capitalize', my: 2, mx: 2, color: 'white', display: 'block', fontFamily: 'Montserrat' }}
                                    >
                                    <div className="flex gap-[5px] items-center" onClick={()=>{navigate(page.link)}}>
                                        <Link className="text-[9pt]">{page.title}</Link>
                                       {(page.title == "World Data")? <img className="h-[25px] w-[25px]" src={process.env.PUBLIC_URL + "/assets/icons/globe.svg"}/> : <></>}
                                    </div>
                                </Button>
                            ))}
                        </Box>

                        <Box onClick={()=>{setShowExplore(true)}} sx={{ flexGrow: 1, ml: 5, display: { cursor: 'pointer', xs: 'none', lg: 'flex' } }}>
                            <div className="flex gap-2 items-center bg-primary px-[25px] py-[10px] rounded-[20px]">
                                <i className="fa-solid fa-search"></i>
                                <p className="text-white text-[10pt] text-semibold font-Montserrat">Explore our data, charts and maps</p>
                            </div>
                        </Box>
                        
                        <Box onClick={()=>{setShowExplore(true)}} sx={{ cursor: "pointer", flexGrow: 1, justifyContent: "end", ml: 5, display: { xs: 'flex', lg: 'none' } }}>
                            <FaSearch className="text-[15pt]"/>
                        </Box>

                        {/* user avatar section */}
                        {(token) ? <Box className="flex gap-2 items-center" sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, pl: 3 }}>
                                    <Avatar sx={{ background: '#034179', border: '2px solid #80E061' }} alt="" src="/assets/img/user_avatar.svg" />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting) => (
                                    <MenuItem key={setting} onClick={() => { handleCloseUserMenu(setting) }}>
                                        <Typography textAlign="center" fontFamily="Montserrat" fontSize="9pt">{setting}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box> : ""}

                    </Toolbar>
                </Container>
            </AppBar>
        </>
    );
}
export default ResponsiveAppBar;