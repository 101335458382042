import { createSlice } from "@reduxjs/toolkit"

export const filterSlice = createSlice({
    name: "filter",
    initialState: {
        value: {
            petitions: { year: 2023 },
            judgements: { year: 2023 }
        },
    },
    reducers: {
        setFilter: (state, action)=>{
            state.value[action.payload.key][action.payload.filter] = action.payload.value
        },
        clearFilter: (state, action)=>{
            delete state.value[action.payload.key][action.payload.filter]
        }
    }
})

export const { setFilter, clearFilter } = filterSlice.actions
export default filterSlice.reducer