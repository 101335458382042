import BarChart from "../charts/BarChart"
import AreaChart from "../charts/AreaChart"
import MultiAxisChart from "../charts/MultiAxisChart"
import axios from "axios"
import {QueryClient, QueryClientProvider, useQuery} from "@tanstack/react-query"
import ClipLoader from "react-spinners/ClipLoader";
import {useState} from "react"
import HighChartMap from "../charts/HighChartMap"

const queryClient = new QueryClient()

export default (props)=>{
 
    return (
        <QueryClientProvider client={queryClient}>
            <Charts query={props.filterQuery} />
        </QueryClientProvider>
    )
}


const Charts = ({query=""})=>{
    const [page, setPage] = useState(0)
    const {isLoading, error, data} = useQuery({
        queryKey: ["petitionsStats" + query], // it is important to have a different key each time the query is different
        queryFn: ()=>axios.get(process.env.REACT_APP_API_HOST + "/petitions/stats/filter?" + query).then((data)=>data.data),
        retry: 3
    })

    if(isLoading) return <div style={{height: "70vh"}} className="flex items-center justify-center"><ClipLoader color="#034179" /></div>
    if(error && !data) return <p class="text-center mt-[20px] font-Montserrat text-primary">Unable to retrieve charts data, will try again in a moment</p>

    const pages = [<PageOne data={data.data}/>, <PageTwo data={data.data}/>]

    return (
        <div className="flex py-[50px] gap-[30px] max-[1380px]:flex-col">
            <div className="min-[850px]:min-w-[500px] p-[20px] bg-[#F0F8FF] items-center shadow-[0px_0px_5px_#c6E4FF19] border-[#2D9BFF40] border-[1px] rounded-[20px]">
                <HighChartMap title="Disaggregation of Petitions per State" data={data.data.stats}/>
            </div>

            {/* other charts */}
            <div className="w-full relative grid grid-cols-2 gap-[15px] grid-rows-2 max-[850px]:grid-cols-1">
                
                {pages[page]}
                
                {/* navigation */}
                <div className="w-full flex absolute bottom-0">                                              
                    <div className={["w-[50%]", (page == 0)? "invisible" : ""].join(" ")}>
                        <a onClick={()=>setPage(page - 1)} className="cursor-pointer fa-solid fa-arrow-left text-[15pt] bg-tertiary shadow-[0px_0px_3px_#B8FFA1] text-white rounded-full p-[10px_12px]"></a>
                    </div>
                    
                    <div className={["w-[50%]", "text-right", (page >= pages.length - 1)? "invisible" : ""].join(" ")}>
                        <a onClick={()=>setPage(page + 1)} className="cursor-pointer fa-solid fa-arrow-right text-[15pt] bg-tertiary shadow-[0px_0px_3px_#B8FFA1] text-white rounded-full p-[10px_12px]"></a>
                    </div>
                </div>                    
            </div>
        </div>
    )
}

const PageOne = (props)=>{
    const rulingStats = JSON.parse(props.data.rulingsStats)
    const rulingByElectionType = JSON.parse(props.data.catRulingsStats)

    return (
        <>
            {/* total number of petitions */}
            <div className="h-full w-full p-[30px] bg-[#F0F8FF] flex items-center shadow-[0px_0px_5px_#c6E4FF19] border-[#2D9BFF40] border-[1px] rounded-[20px]">
                <div className="w-full">
                    <i className="text-lg fa-solid fa-scale-balanced px-[15px] py-[12px] bg-primary rounded-full text-white"></i>
                    <div className="flex">
                        <div className="w-full">
                            <p className="text-primary font-Baskerville font-bold text-lg mt-[20px] mb-[20px]">Total Petitions</p>
                            <p className="text-primary font-Montserrat font-bold text-2xl">{props.data.totalPetitions}</p>
                        </div>
                        <div className="max-w-[100px]">
                            <img src="/assets/img/coat_of_arms.png"/>
                        </div>
                    </div>
                </div>
            </div>

            {/* total number of petitions dispensed  */}
            <div className="h-full w-full p-[30px] bg-[#F0F8FF] flex items-center shadow-[0px_0px_5px_#c6E4FF19] border-[#2D9BFF40] border-[1px] rounded-[20px]">
                <div className="w-full">
                    <i className="text-lg fa-solid fa-gavel px-[15px] py-[10px] bg-primary rounded-full text-white"></i>
                    <p className="text-secondary font-Baskerville font-bold text-lg mt-[20px] mb-[20px]">Total Petitions Dispensed</p>
                    <div className="flex items-end">
                        <p className="text-secondary w-full font-Montserrat font-bold text-2xl">{props.data.dispensedPetitions}</p>
                        <p className="text-secondary_teal font-Montserrat font-bold max-w-[100px] text-2lg">{props.data.percentDispensed}%</p>
                    </div>
                </div>
            </div>

            {/* disaggregation of judgements and rulings */}
            <div className="h-full w-full p-[20px] bg-[#F0F8FF] justify-center items-center shadow-[0px_0px_5px_#c6E4FF19] border-[#2D9BFF40] border-[1px] rounded-[20px]">
                <BarChart 
                    title="Disaggregation of Judgements and rulings"
                    labels={["Withdrawn", "Lost", "Won"]}
                    data={rulingStats[1]}
                    colors={["#65B14D", "#139DB8", "#034179"]}
                />
            </div>
            
            {/* petitions won and lost per eletion type */}
            <div className="h-full w-full p-[20px] bg-[#F0F8FF] justify-center items-center shadow-[0px_0px_5px_#c6E4FF19] border-[#2D9BFF40] border-[1px] rounded-[20px]">
                <MultiAxisChart 
                    title="Petitions won and lost per election type"
                    labels={rulingByElectionType[0]}
                    series={
                        [
                            {
                                name: "Petitions Lost",
                                data: rulingByElectionType[1].lost,
                            },
                            {
                                name: "Petitions Won",
                                data: rulingByElectionType[1].win
                            },                            
                        ]}
                    legend={true}
                    colors={
                        [
                            '#13d8aa',
                            '#275c8d'
                        ]    
                    }
                />
            </div>
        </>
    )
}

const PageTwo = (props)=>{
    const withdrawnGeoZonesStats = JSON.parse(props.data.withdrawnGeoZonesStats)
    const withdrawnCatsStats = JSON.parse(props.data.withdrawnCatsStats)
    return (
        <>
        <div className="h-full w-full p-[30px] bg-[#F0F8FF] flex items-center shadow-[0px_0px_5px_#c6E4FF19] border-[#2D9BFF40] border-[1px] rounded-[20px]">
                <div className="w-full">
                    <i className="text-lg fa-solid fa-scale-balanced px-[15px] py-[12px] bg-primary rounded-full text-white"></i>
                    <div className="flex">
                        <div className="w-full">
                            <p className="text-primary font-Baskerville font-bold text-lg mt-[20px] mb-[20px]">Total Petitions</p>
                            <p className="text-primary font-Montserrat font-bold text-2xl">{props.data.totalPetitions}</p>
                        </div>
                        <div className="max-w-[100px]">
                            <img src="/assets/img/coat_of_arms.png"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="h-full w-full p-[30px] bg-[#F0F8FF] flex items-center shadow-[0px_0px_5px_#c6E4FF19] border-[#2D9BFF40] border-[1px] rounded-[20px]">
                <div className="w-full">
                    <i className="text-lg fa-solid fa-gavel px-[15px] py-[10px] bg-primary rounded-full text-white"></i>
                    <p className="text-secondary font-Baskerville font-bold text-lg mt-[20px] mb-[20px]">Total Petitions Dispensed</p>
                    <div className="flex items-end">
                        <p className="text-secondary w-full font-Montserrat font-bold text-2xl">{props.data.dispensedPetitions}</p>
                        <p className="text-secondary_teal font-Montserrat font-bold max-w-[100px] text-2lg">{props.data.percentDispensed}%</p>
                    </div>
                </div>
            </div>

            <div className="h-full w-full p-[30px] bg-[#F0F8FF] flex items-center shadow-[0px_0px_5px_#c6E4FF19] border-[#2D9BFF40] border-[1px] rounded-[20px]">
                <AreaChart 
                    title="Petitions withdrawn per geo-political zone"
                    labels={withdrawnGeoZonesStats[0]}
                    data={withdrawnGeoZonesStats[1]}
                    colors={["#65B14D", "#139DB8", "#034179", ]}
                />
            </div>

            <div className="h-full w-full p-[30px] bg-[#F0F8FF] flex items-center shadow-[0px_0px_5px_#c6E4FF19] border-[#2D9BFF40] border-[1px] rounded-[20px]">
                <BarChart 
                    title="Petitions withdrawn per election type"
                    labels={withdrawnCatsStats[0]}
                    data={withdrawnCatsStats[1]}
                    colors={["#65B14D", "#139DB8", "#034179", "#65B14D", "#139DB8", "#034179"]}
                    horizontal={true}
                />
            </div>

            {/* <div className="h-full w-full p-[20px] bg-[#F0F8FF] justify-center items-center shadow-[0px_0px_5px_#c6E4FF19] border-[#2D9BFF40] border-[1px] rounded-[20px]">
                <BarChart 
                    title="Disaggregation of Jugements and rulings"
                    labels={["Withdrawn", "Petitions Lost", "Petitions Won"]}
                    data={[230, 900, 109]}
                    colors={["#65B14D", "#139DB8", "#034179"]}
                />
            </div>
            <div className="h-full w-full p-[20px] bg-[#F0F8FF] justify-center items-center shadow-[0px_0px_5px_#c6E4FF19] border-[#2D9BFF40] border-[1px] rounded-[20px]">
                <MultiAxisChart 
                    title="Petitions won and lost per election type"
                    labels={["Sen", "HoR", "Gov", "S/HoR", "Pres"]}
                    series={
                        [
                            {
                                name: "Petitions Lost",
                                data: [142, 375, 81, 504, 5], 
                            },
                            {
                                name: "Petitions Won",
                                data: [6, 42, 0, 53, 0]
                            },                            
                        ]}
                    legend={true}
                    colors={
                        [
                            '#33b2df',
                            '#546E7A',
                            '#d4526e',
                            '#13d8aa',
                            '#A5978B'
                        ]    
                    }
                />
            </div> */}
        </>
    )
}
